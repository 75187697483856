<template>
  <v-dialog v-model="visibility" max-width="568px" persistent>
    <v-card class="modalBackground">
      <v-icon
        size="24px"
        color="#212121"
        large
        style="position: absolute; right: 20px; cursor: pointer"
        @click="$emit('close')"
        >mdi-close</v-icon
      >
      <p class="attractionName" style="text-align: center; width: 95%">
        {{ attraction.title }}
      </p>
      <p
        v-if="!showLoader && timeList.length > 0"
        class="bookingDate"
        style="margin-top: 20px; font-weight: 400"
      >
        {{ "prebooking_dialogue_user_hint" | localize }}
      </p>
      <div style="height: 400px; overflow-y: scroll">
        <Loader v-if="showLoader" />
        <v-col v-else-if="!showLoader && timeList.length == 0">
          <p
            style="text-align: center; margin-bottom: 10px"
            class="bookingDate"
          >
            {{ "attractions_prebooking_no_slots_message_1" | localize }}
          </p>
          <p
            style="text-align: center; margin-bottom: 30px"
            class="bookingDate"
          >
            {{ "attractions_prebooking_no_slots_message" | localize }}
          </p>
        </v-col>
        <div v-else v-for="item in timeList" :key="item.id">
          <p class="bookingDate" v-if="timeList.length > 0">
            {{
              new Date(item.day.replace(/-/g, '/')).toLocaleDateString(
                loggedUser
                  ? loggedUser.locale == "en"
                    ? "en-GB"
                    : "es-ES"
                  : user.locale == "en"
                  ? "en-GB"
                  : "es-ES",
                {
                  weekday: "long",
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
                }
              )
            }}
          </p>
          <v-row no-gutters align="center">
            <button
              v-for="time in item.times"
              :key="time.id"
              class="timeCard"
              :class="choosedEvents.includes(time.uuid) ? 'activeTimeCard' : ''"
              @click="chooseEvent(time)"
            >
              {{
                new Date(adaptDate(time.started_at))
                  .toLocaleTimeString("en-gb", {
                    hour12: false,
                  })
                  .slice(0, 5)
              }}
              -
              {{
                new Date(adaptDate(time.ended_at))
                  .toLocaleTimeString("en-gb", {
                    hour12: false,
                  })
                  .slice(0, 5)
              }}
            </button>
          </v-row>
          <v-divider />
        </div>
      </div>
      <v-row no-gutters justify="end" v-if="timeList.length > 0">
        <v-btn
          v-if="!selectedEvent"
          class="reserveBtn"
          :style="disabledBtn ? 'opacity: .5; pointer-events: none;' : ''"
          @click="disabledBtn ? '' : reserve()"
          >{{ "attractions_prebooking_reserve_button" | localize }}</v-btn
        >
        <v-btn
          v-if="selectedEvent"
          class="reserveBtn"
          :style="disabledBtn ? 'opacity: .5; pointer-events: none;' : ''"
          @click="disabledBtn ? '' : reserve()"
          >{{ "button_save" | localize }}</v-btn
        >
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import eventsService from "@/requests/Events/eventsService";
import Loader from "../UI/Loader.vue";
import { mapGetters } from "vuex";
import dateMixin from "../../mixins/dateMixin";
export default {
  mixins: [dateMixin],
  components: {
    Loader,
  },
  data: () => ({
    timeList: [],
    choosedEvents: [],
    choosedEvent: {},
    disabledBtn: true,
    disabledChoose: false,
    showLoader: true,
  }),
  props: {
    visible: {
      require: true,
    },
    attraction: {
      require: true,
    },
    startCitypassDate: {
      require: true,
    },
    endCitypassDate: {
      require: true,
    },
    adultNum: {
      require: true,
    },
    childNum: {
      require: true,
    },
    selectedEvent: {
      require: false,
    },
  },
  mounted() {
    if (this.selectedEvent) {
      this.choosedEvent.uuid = this.selectedEvent;
      this.choosedEvents.push(this.selectedEvent);
    }
    console.log(this.selectedEvent);
    if (this.visible && this.attraction) {
      this.showLoader = true;
      setTimeout(() => {
        this.getEvents();
      }, 100);
    }
  },
  methods: {
    reserve() {
      this.$emit("chooseTime", this.choosedEvent);
    },
    async getEvents() {
      //ENDED CITYPASS DATE
      let ended_date = new Date(
        this.endCitypassDate.toString()
      ).toLocaleDateString("en-gb", {
        timeZone: "Europe/London",
      });
      ended_date = `${ended_date.substring(6, 10)}-${ended_date.substring(
        3,
        5
      )}-${ended_date.substring(0, 2)}`;
      //CURRENT TIME FOR CORECT SHOW SLOT
      let current_time = new Date().toLocaleTimeString("en-GB", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      });
      //CHECK IF CHOSED DATE IS TODAY
      let isToday =
        `${this.startCitypassDate.substr(8, 2)} ${new Date(
          this.startCitypassDate
        ).getMonth()}` ==
        `${new Date().toLocaleString("en-GB", {
          day: "2-digit",
        })} ${new Date().getMonth()}`;
      //GET EVENTS FOR CITYPASS BY START AND END TIME
      console.log(
        "start date",
        this.startCitypassDate,
        "enddate",
        ended_date,
        `${this.startCitypassDate} ${isToday ? current_time : "00:00:00"}`
      );
      await eventsService
        .getEvents(
          `attraction_uuid=${this.attraction.uid}&started_at=${
            this.startCitypassDate
          } ${
            isToday ? current_time : "00:00:00"
          }&ended_at=${ended_date} 23:59:59`
        )
        .then((res) => {
          console.log(res.result);
          if (res.status == "success") {
            res.result = res.result.filter((el) => {
              return (
                (Number(el.adult) - Number(el.booked_adult) >=
                  Number(this.adultNum) &&
                  Number(el.kid) - Number(el.booked_kid) >=
                    Number(this.childNum)) ||
                el.uuid == this.selectedEvent
              );
            });
            //CLEAR OLD EVENTS
            this.timeList = [];
            //ADD TEMP ARRAY FOR GET DAYS AND FILTER BY QNIQUE
            let days = [];
            //FILTER DAYS
            res.result.forEach((time) => {
              days.push(time.started_at.substr(0, 10));
              this.timeList.push({ isActive: false, ...time });
            });
            //REMOVE DUPLICATE
            days = [...new Set(days)];
            //TIMES ARRAY FROM FORMATES VALID ARRAY
            let times = [];
            //FORMATED VALIDA ARRAY
            days.forEach((day) => {
              times.push({
                day: day,
                times: this.timeList.filter(
                  (time) => time.started_at.substr(0, 10) == day
                ),
              });
            });
            //SORT ARRAY BY DATE
            times = times.sort((a, b) => new Date(a.day) - new Date(b.day));
            times.forEach(
              (time) =>
                (time.times = time.times.sort(
                  (a, b) => new Date(a.started_at) - new Date(b.started_at)
                ))
            );
            //UPDATE TIME LIST
            this.timeList = times;
          }
          if (this.attraction.eventUid && this.attraction.eventUid !== "") {
            let event = "";
            res.result.forEach((time) => {
              if (time.uuid == this.attraction.eventUid) {
                event = time;
              }
            });
            if (event) {
              this.chooseEvent(event);
            } else {
              this.choosedEvent = {};
              this.chooseEvent = [];
            }
          }
          //HIDE LOADER
          this.showLoader = false;
        });
    },
    chooseEvent(event) {
      if (this.choosedEvents.length > 0) {
        if (this.choosedEvents[0] == event.uuid) {
          this.choosedEvents = [];
          this.choosedEvent = {};
        } else if (this.choosedEvents?.[0] !== event.uuid) {
          this.choosedEvents = [];
          this.choosedEvents.push(event.uuid);
          this.choosedEvent = event;
        }
      } else {
        this.choosedEvents = [];
        this.choosedEvents.push(event.uuid);
        this.choosedEvent = event;
      }
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close_modal");
      },
    },
  },
  watch: {
    choosedEvents: {
      deep: true,
      handler() {
        this.choosedEvents.length > 0
          ? ((this.disabledBtn = false), (this.disabledChoose = true))
          : ((this.disabledBtn = true), (this.disabledChoose = false));
      },
    },
    startCitypassDate: {
      handler() {
        if (this.startCitypassDate && this.endCitypassDate) {
          this.showLoader = true;
          this.getEvents();
        }
      },
    },
    endCitypassDate: {
      handler() {
        if (this.startCitypassDate && this.endCitypassDate) {
          this.showLoader = true;
          this.getEvents();
        }
      },
    },
    visible: {
      handler() {
        if (this.visible && this.startCitypassDate && this.endCitypassDate) {
          this.showLoader = true;
          this.getEvents();
        }
      },
    },
    attraction: {
      handler() {
        if (this.attraction) {
          this.showLoader = true;
          this.getEvents();
        }
      },
    },
  },
};
</script>

<style scoped>
.modalBackground {
  padding: 20px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  overflow-y: scroll;
}
.attractionName {
  color: #212121;
  font-size: 32px;
  font-family: Montserrat;
  font-weight: 600;
  margin-bottom: 0px;
}
.bookingDate {
  margin-top: 10px;
  color: #212121;
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 600;
  margin-bottom: 10px;
}
.timeCard {
  border-radius: 6px;
  border: 1px solid #6abaa4;
  width: 117px;
  height: 32px;
  display: grid;
  place-content: center;
  color: #212121;
  text-align: center;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 500;
  margin-right: 20px;
  margin-bottom: 20px;
}
.timeCard:nth-child(4n) {
  margin-right: 0px;
}
.activeTimeCard {
  background: #6abaa4 !important;
  font-weight: 700 !important;
  color: #fff !important;
}
.reserveBtn {
  color: #fff !important;
  text-align: center;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 700;
  border-radius: 6px;
  border-bottom: 2px solid #4e8777;
  background: #6abaa4 !important;
  text-transform: none;
  width: 255px !important;
  height: 44px !important;
  display: grid;
  place-content: center;
  margin-top: 40px;
}
</style>