<template>
  <div>
    <v-idle
      @idle="onidleSelectInfo"
      :events="['keydown', 'mousedown', 'touchstart']"
      hidden
      :duration="5"
      :loop="true"
    />
    <!-- <v-idle
      @idle="onidleSelectAttractions"
      :events="['keydown', 'mousedown', 'touchstart']"
      hidden
      :duration="15"
      :loop="true"
    /> -->
    <v-card
      :width="$vuetify.breakpoint.xs ? '343px' : '265px'"
      color="#6ABAA4"
      class="rounded-lg"
    >
      <v-col class="py-0 pt-3" style="margin-bottom: 20px">
        <span style="font-weight: 600; font-size: 20px" class="white--text">
          {{ "attractions_choose_city_pass" | localize }}
        </span>
      </v-col>
      <v-col class="py-0 pb-2">
        <v-select
          style="cursor: pointer"
          outlined
          class="rounded-lg mb-5"
          background-color="white"
          dense
          :items="Object.values(citypassList)"
          :item-value="'uuid'"
          :item-text="locale == 'en' ? 'title_en' : 'title_es'"
          v-model="selectedCitypassId"
          :menu-props="{ bottom: true, offsetY: true }"
          color="black"
          autocomplete="new-password"
          :placeholder="'attractions_choose_city_pass' | localize"
          :error-messages="selectedCitypassIdError"
          hide-details
        >
          <template slot="item" slot-scope="{ item }">
            <v-list-tile-content class="px-0">
              <v-row align="center" class="px-0">
                <div
                  style="width: 5px; height: 30px"
                  :style="'background-color:' + item.color"
                ></div>
                &nbsp;
                <span style="font-weight: 500; font-size: 14px">
                  {{
                    loggedUser
                      ? loggedUser.locale == "en"
                        ? item.title_en
                        : item.title_es
                      : user.locale == "en"
                      ? item.title_en
                      : item.title_es
                  }}
                </span>
              </v-row>
            </v-list-tile-content>
          </template>
        </v-select>
        <v-menu ref="menu" v-model="menu" :close-on-content-click="isClose">
          <template v-slot:activator="{ on, attrs }">
            <v-row justify="start" no-gutters align="center">
              <v-col
                class="px-0 py-0 ml-1"
                :class="
                  loggedUser
                    ? loggedUser.locale == 'en'
                      ? ''
                      : 'mt-1'
                    : user.locale == 'en'
                    ? ''
                    : 'mt-1'
                "
              >
                <span class="white--text">
                  {{ "attractions_starting_date" | localize }}
                </span>
              </v-col>

              <v-col cols="7" style="font-size: 12px">
                <v-text-field
                  outlined
                  :value="
                    date
                      ? new Date(date).toLocaleDateString('en-GB', {
                          timeZone: 'Europe/London',
                        })
                      : ''
                  "
                  :disabled="selectedCitypassId == ''"
                  dense
                  class="rounded-lg"
                  background-color="white"
                  color="black"
                  v-bind="attrs"
                  v-on="on"
                  append-icon="mdi-calendar"
                  @click:append="on.click"
                  readonly
                  hide-details
                  :placeholder="'attractions_placeholder_starting' | localize"
                  :error-messages="dateError"
                  @blur="$v.date.$touch()"
                  @click="isClose = false"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
          <v-date-picker
            v-model="date"
            color="#6ABAA4"
            :locale="loggedUser ? loggedUser.locale : user.locale"
            :allowed-dates="allowedDates"
            :full-width="$vuetify.breakpoint.xs"
            :min="
              new Date(
                new Date().getTime() +
                  1 * 60 * 60 * 1000 -
                  new Date().getTimezoneOffset() * 60000
              )
                .toISOString()
                .substr(0, 10)
            "
            :max="
              new Date(
                new Date().getTime() +
                  1464 * 60 * 60 * 1000 -
                  new Date().getTimezoneOffset() * 60000
              )
                .toISOString()
                .substr(0, 10)
            "
          ></v-date-picker>
        </v-menu>
        <v-row no-gutters align="center" class="mt-3 mb-1" v-if="endDate">
          <v-col class="white--text py-0 ml-1">{{
            "attractions_end_date" | localize
          }}</v-col>
          <v-col cols="7" style="font-size: 12px" class="px-0">
            <v-text-field
              outlined
              :value="
                endDate.toLocaleDateString('en-gb', {
                  timeZone: 'Europe/London',
                })
              "
              disabled
              dense
              class="rounded-lg"
              background-color="white"
              color="black"
              append-icon="mdi-calendar"
              readonly
              hide-details
              :placeholder="'attractions_placeholder_starting' | localize"
              :error-messages="dateError"
              @blur="$v.date.$touch()"
            ></v-text-field>
          </v-col>
          <!-- <v-col class="white--text py-0">
            <div v-mask="'##/##/##'">
              {{
                endDate.toISOString().substr(2, 2) +
                "/" +
                endDate.toISOString().substr(5, 2) +
                "/" +
                endDate.toISOString().substr(8, 2)
              }}
            </div>
          </v-col> -->
        </v-row>
        <v-row align="center" class="px-1 mb-1">
          <v-col class="white--text py-0" cols="6">
            <span class="font-weight-bold">
              {{ "attractions_adult" | localize }}
            </span>
            <br />
            <!-- <div v-if="selectedCitypass.price_adult">
              <span class="font-weight-bold">
                MX$
                {{
                  selectedCitypass.price_adult
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </span>
            </div> -->
          </v-col>
          <v-col cols="6" class="px-0 py-0 pr-2">
            <v-row align="center" justify="end" class="px-1">
              <v-btn
                icon
                color="white"
                x-large
                :disabled="!selectedCitypassId || adultNumber == 0"
                @click="adultNumber > 0 ? adultNumber-- : (adultNumber = 0)"
              >
                <v-icon>mdi-minus-circle-outline</v-icon>
              </v-btn>
              <v-col cols="2" class="px-0 py-0" style="text-align: center">
                <span class="white--text">{{ adultNumber }}</span>
              </v-col>
              <v-btn
                icon
                color="white"
                x-large
                @click="verifyCountAdult"
                :disabled="!selectedCitypassId || adultNumber == 15"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <v-row align="center" class="px-1">
          <v-col class="white--text py-0" cols="6">
            <span class="font-weight-bold">
              {{ "attractions_child" | localize }}
            </span>
            <br />
            <!-- <div v-if="selectedCitypass.price_kid">
              <span class="font-weight-bold">
                MX$
                {{
                  selectedCitypass.price_kid
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </span>
            </div> -->
          </v-col>
          <v-col cols="6" class="px-0 py-0 pr-2">
            <v-row align="center" justify="end" class="px-1">
              <v-btn
                icon
                color="white"
                x-large
                @click="childNumber > 0 ? childNumber-- : (childNumber = 0)"
                :disabled="!selectedCitypassId || childNumber == 0"
              >
                <v-icon>mdi-minus-circle-outline</v-icon>
              </v-btn>
              <v-col class="px-0 py-0" cols="2" style="text-align: center">
                <span class="white--text">{{ childNumber }}</span>
              </v-col>
              <v-btn
                icon
                color="white"
                x-large
                @click="verifyCountChild"
                :disabled="!selectedCitypassId || childNumber == 40"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          justify="start"
          class="mt-5 mb-1"
          style="margin-left: 4px"
        >
          <v-col cols="9" xl="6" lg="6" md="6" sm="6">
            <div>
              <span class="font-weight-bold white--text">
                {{ "attractions_infants_label" | localize }}
              </span>
            </div>
          </v-col>
          <v-col cols="2" xl="6" lg="6" md="6" sm="6" class="text-center">
            <div
              class="ml-sm-0"
              :class="$vuetify.breakpoint.xs ? 'pr-2' : 'pl-2'"
            >
              <span class="white--text">
                {{ "attractions_infants_label_2" | localize }}
              </span>
            </div>
          </v-col>
        </v-row>
        <!-- GDL Bus/Metro -->
        <v-row
          no-gutters
          justify="center"
          align="center"
          v-if="isAdditionalAttraction"
        >
          <v-divider color="white" style="border: 0.5px solid #ffffff" />
          <div
            style="
              width: 30px;
              height: 28px;
              object-fit: cover;
              margin-left: 21px;
              margin-right: 21px;
            "
          >
            <v-img src="@/assets/ourAttractionBusIcon.svg" />
          </div>
          <v-divider color="white" style="border: 0.5px solid #ffffff" />
        </v-row>
        <v-row
          v-if="isAdditionalAttraction"
          no-gutters
          align="center"
          class="px-0"
          style="margin-top: 10px; margin-bottom: 15px"
        >
          <div style="width: 28px; height: 28px">
            <img
              src="@/assets/notChecked.svg"
              style="cursor: pointer"
              v-if="!isIncludeGDLPublicTransport"
              @click="isIncludeGDLPublicTransport = true"
            />
            <div style="" v-if="isIncludeGDLPublicTransport">
              <img
                src="@/assets/chekedTerm.svg"
                @click="isIncludeGDLPublicTransport = false"
                style="cursor: pointer"
              />
            </div>
          </div>

          <v-col cols="10" class="px-0 pl-4">
            <span style="color: #ffffff">
              {{ "attractions_include_transport_label" | localize }}
              <router-link
                :to="'/attractions/' + additionalAttractionUuid"
                style="color: white"
              >
                <span style="cursor: pointer">
                  {{ "attractions_include_transport_label_2\n" | localize }}
                </span>
              </router-link>
            </span>
            <!--{{ "public_transport_label1" | localize }}<br /><u
                style="cursor: pointer"
                @click="showTermsModal = true"
                >{{ "public_transport_label2" | localize }}</u
              >{{ "public_transport_label3" | localize }} -->
          </v-col>
        </v-row>
        <!-- /GDL Bus/Metro -->
      </v-col>
    </v-card>
    <v-card
      v-if="
        !$v.$invalid &&
        (adultNumber || childNumber) &&
        choseAttractions &&
        !disabledCart &&
        orderTotal.toString() != '0'
      "
      class="rounded-lg px-1 mt-1 py-2 px-2"
      color="#6ABAA4"
      :width="$vuetify.breakpoint.xs ? '343px' : '265px'"
    >
      <v-col class="white--text">
        <!-- <v-row>
          <span>Sale Discount</span>
          <v-spacer></v-spacer>
          <span>-MX$ {{ 0 }}</span>
        </v-row>
        <v-row>
          <span>Booking Fee</span>
          <v-spacer></v-spacer>
          <span
            >MX$ {{
              new Intl.NumberFormat("de-DE", {
                minimumFractionDigits: 2,
              }).format(bookingFee)
            }}</span
          >
        </v-row> -->
        <v-row class="font-weight-bold px-2" justify="center">
          <span>{{ "attractions_order_total" | localize }}</span>
          <v-spacer></v-spacer>
          <span>
            MX$
            {{
              new Intl.NumberFormat("en-IN", {
                minimumFractionDigits: 2,
              })
                .format(orderTotal)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }}
          </span>
        </v-row>
      </v-col>
    </v-card>
    <div v-if="isChooseCitypass" id="choose_attr">
      <v-card
        style="z-index: 7 !important; position: relative"
        v-if="!choseAttractions"
        color="#40B4E5"
        :width="$vuetify.breakpoint.xs ? '343px' : '265px'"
        height="90px"
        :class="userLocale == 'en' ? 'mt-4 px-4' : 'mt-4 px-2'"
      >
        <!-- <v-btn
          v-if="overlay"
          @click="overlay = false"
          icon
          absolute
          small
          right
          color="gray"
          style="top: -10px; right: -10px; background-color: white"
          ><v-icon>mdi-close</v-icon></v-btn
        > -->
        <v-row align="center" class="white--text blink_me">
          <v-col :class="userLocale == 'en' ? 'mt-2' : 'px-0 pl-3 mt-1'">
            <span
              style="
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 19px;
                line-height: 24px;
              "
            >
              {{ "attractions_flashing_banner_part1" | localize }}
            </span>
            <br v-if="userLocale == 'en'" />
            <span
              style="
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 19px;
                line-height: 24px;
              "
            >
              {{ selectedCitypass.attraction_count }}
              {{ "attractions_flashing_banner_part2" | localize }}
            </span>
          </v-col>
          <v-icon v-if="$vuetify.breakpoint.xs" x-large color="white">
            mdi-arrow-down
          </v-icon>
          <v-icon v-else x-large color="white">mdi-arrow-right</v-icon>
        </v-row>
      </v-card>
      <v-btn
        height="45px"
        color="#E62076"
        :width="$vuetify.breakpoint.xs ? '343px' : '265px'"
        class="mt-1 white--text"
        style="
          text-transform: unset !important;
          animation: blinker 2s linear infinite;
        "
        @click="addToCart"
        v-if="
          !$v.$invalid &&
          (adultNumber || childNumber) &&
          choseAttractions &&
          !disabledCart &&
          orderTotal.toString() != '0'
        "
      >
        {{ "attractions_add_to_cart_button" | localize }}
      </v-btn>
    </div>
    <div
      class="mt-3"
      v-if="selectedCitypassId == ''"
      :style="
        mapFull
          ? 'position: fixed;top: 0;left: 0;width: 100%;height: 100%;background-color: white; z-index: 7 !important'
          : 'position: relative; width: 100%'
      "
    >
      <v-btn
        color="#fff"
        min-width="30px"
        class="pa-0"
        :style="
          $vuetify.breakpoint.xs || mapFull
            ? 'position: absolute;z-index: 2;right: 5px; top: 5px;width: 30px;height: 30px; padding: 0;'
            : 'position: absolute;z-index: 2;left: 227px;top: 5px;width: 30px;height: 30px; padding: 0;'
        "
        @click="changeStyle()"
      >
        <v-icon>mdi-fullscreen</v-icon>
      </v-btn>

      <gmap-map
        ref="map"
        :style="
          mapFull
            ? 'position: fixed;top: 0;left: 0;width: 100%;height: 100%;background-color: white;overflow-y: hidden;'
            : $vuetify.breakpoint.xs
            ? 'width: 100%; height: 262px; z-index: 1; !important'
            : 'width: 262px; height: 262px; z-index: 1; !important'
        "
        :center="center"
        :zoom="zoom"
        :options="options"
        map-type-id="roadmap"
      >
        <gmap-info-window
          :options="infoOptions"
          :position="infoWindowPos"
          :opened="infoWinOpen"
          @closeclick="infoWinOpen = false"
        ></gmap-info-window>
        <gmap-marker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          @click="toggleInfoWindow(m, index)"
        />
      </gmap-map>
    </div>
    <!-- <v-btn
      :width="$vuetify.breakpoint.xs ? '343px' : '262px'"
      style="text-transform: unset !important"
      color="#6ABAA4"
      class="white--text font-weight-medium button mt-1"
      href="https://www.hoozaround.com/promo"
      target="_blank"
    >
      {{ "attractions_transport_button" | localize }}
    </v-btn> -->
    <terms-modal
      v-if="showTermsModal"
      rules="publicTransport"
      @close_modal="showTermsModal = false"
    />
    <confirm-modal
      v-if="noGdlModal"
      :visible="noGdlModal"
      @close_modal="noGdlModal = false"
      @choosed="choosedConfirm"
      :typeConfirm="typeConfirm"
    />
    <!-- <v-overlay z-index="6" :value="overlay"></v-overlay> -->
    <!-- <announcement-modal
      :visible="announcementModal"
      v-if="announcementModal"
      @close="announcementModal = false"
      @success="announcementModal = false; isSuccessSecureModal = true"
    /> -->
    <booking-validation-dialog
      v-if="bookingValidDialog"
      :visible="bookingValidDialog"
      @choosed="choosedBookingValid"
    />
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import TermsModal from "../UI/TermsModal.vue";
import cityMixin from "@/mixins/cityMixin";
import confirmModal from "./confirmModal.vue";
import CartService from "@/requests/Cart/CartService";
//import announcementModal from "@/components/UI/announcementModal.vue";
import serverMixin from "@/mixins/serverMixin";
import userSettingsMixin from "@/mixins/userSettingsMixin.js";
import bookingValidationDialog from "./bookingValidationDialog.vue";

export default {
  components: { TermsModal, confirmModal, bookingValidationDialog },
  mixins: [validationMixin, cityMixin, serverMixin, userSettingsMixin],
  data: () => ({
    infoWindowPos: null,
    currentMidx: null,
    menu: null,
    endDate: null,
    infoWinOpen: false,
    isClose: false,
    isIncludeGDLPublicTransport: false,
    mapFull: false,
    showTermsModal: false,
    zoom: 10,
    oldPriceAdult: 100,
    oldPriceChild: 50,
    adultNumber: 0,
    childNumber: 0,
    sale: 29,
    center: { lat: 20.6739383, lng: -103.4054541 },
    bookingFee: 0,
    orderTotal: 0,
    width: document.documentElement.clientWidth,
    infoOptions: {
      content: "",
      //optional: offset infowindow so it visually sits nicely on top of our marker
      pixelOffset: {
        width: 0,
        height: -20,
      },
    },
    selectedCitypass: {},
    options: {
      zoomControl: true,
      streetViewControl: true,
      mapTypeControl: false,
      fullscreenControl: false,
    },
    selectedCitypassId: "",
    date: "",
    userLocale: "",
    allowedDates: "",
    mapClass: "fullsize",
    attractions: [],
    transportAttractionPrice: 0,
    // overlay: false,
    noGdlModal: false,
    typeConfirm: "",
    disabledCart: false,
    //announcementModal: false,
    isSuccessSecureModal: false,
    selectSnackbar: {
      visible: false,
      used: false,
    },
    isChooseCitypass: false,
    bookingValidDialog: false,
    eventAttractionToDelete: null,
    typeIncrease: "",
    timeZone: "",
  }),

  // updated() {
  //   if (localStorage.citypass_uuid) {
  //     this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
  //               .toISOString()
  //               .substr(0, 10)
  //               this.selectedCitypassId = 'a27396ca-c4b7-47ea-b613-6a5d5ba7a1d5'
  //               this.selectedCitypassId = localStorage.citypass_uuid
  //               console.log(this.selectedCitypassId)

  //               this.$set("selectedCitypassId", localStorage.citypass_uuid);
  // }
  // },
  destroyed() {
    localStorage.removeItem("citypass_uuid");
  },
  beforeUpdate() {
    if (localStorage.citypass_uuid) {
      this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      this.selectedCitypassId = localStorage.citypass_uuid;
      console.log(this.selectedCitypassId);

      //this.$set(this, "selectedCitypassId", localStorage.citypass_uuid);
    }
  },
  mounted() {
    if (this.mazatlan) {
      this.center = { lat: 23.253522, lng: -106.411635 };
      this.zoom = 11;
    }
    this.setUserLocale();
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  },
  props: {
    markers: {
      require: false,
    },
    citypassList: {
      required: true,
    },
    isSuccess: {
      required: false,
    },
    choseAttractions: {
      required: false,
    },
    isAdditionalAttraction: {
      required: true,
    },
    transportAttractionPriceAdult: {
      required: true,
    },
    transportAttractionPriceChild: {
      required: true,
    },
    additionalAttractionUuid: {
      required: false,
    },
    bookingEvents: {
      required: false,
    },
  },
  validations: {
    selectedCitypassId: {
      required,
    },
    date: {
      required,
    },
  },
  methods: {
    choosedBookingValid(check) {
      if (!check) {
        this.bookingValidDialog = false;
      } else {
        this.$emit("deleteAttraction", this.eventAttractionToDelete);
        this.bookingValidDialog = false;
        if (this.typeIncrease == "child") {
          this.childNumber < 40 ? this.childNumber++ : (this.childNumber = 40);
        } else {
          this.adultNumber < 15 ? this.adultNumber++ : (this.adultNumber = 15);
        }
      }
    },
    verifyCountChild() {
      if (!this.bookingEvents.length) {
        this.childNumber < 40 ? this.childNumber++ : (this.childNumber = 40);
      } else {
        let child_availables = this.bookingEvents.filter((el) => {
          if (
            Number(el.kid) - Number(el.booked_kid) <
            Number(this.childNumber) + 1
          ) {
            return el;
          }
        });

        console.log(child_availables);

        if (child_availables.length) {
          this.typeIncrease = "child";
          this.eventAttractionToDelete = child_availables;
          this.bookingValidDialog = true;
        } else {
          this.childNumber < 40 ? this.childNumber++ : (this.childNumber = 40);
        }
      }
    },
    verifyCountAdult() {
      if (!this.bookingEvents.length) {
        this.adultNumber < 15 ? this.adultNumber++ : (this.adultNumber = 15);
      } else {
        let adult_availables = this.bookingEvents.filter((el) => {
          if (
            Number(el.kid) - Number(el.booked_kid) <
            Number(this.childNumber) + 1
          ) {
            return el;
          }
        });
        if (adult_availables.length) {
          this.eventAttractionToDelete = adult_availables;
          this.typeIncrease = "adult";
          this.bookingValidDialog = true;
        } else {
          this.adultNumber < 15 ? this.adultNumber++ : (this.adultNumber = 15);
        }
      }
    },
    onidleSelectInfo() {
      if (
        this.selectedCitypassId &&
        this.choseAttractions &&
        (!this.date ||
          parseInt(this.adultNumber) + parseInt(this.childNumber) == 0) &&
        !this.selectSnackbar.used
      ) {
        this.selectSnackbar.visible = true;
        this.selectSnackbar.used = true;
        setTimeout(() => {
          this.selectSnackbar.visible = false;
        }, 6000);
      }
    },
    // onidleSelectAttractions() {
    //   let attractions = [];
    //   if (localStorage.attractions) {
    //     attractions = JSON.parse(localStorage.attractions);
    //   }
    //   if (
    //     this.selectedCitypassId &&
    //     (this.adultNumber || this.childNumber) &&
    //     this.date &&
    //     !this.choseAttractions &&
    //     !this.overlay &&
    //     !attractions.length &&
    //     localStorage.getItem("showBookingAttractionModal") == "false"
    //   ) {
    //     // this.overlay = true;
    //     // document.getElementById("choose_attr").scrollIntoView({
    //     //   behavior: "smooth",
    //     //   block: "center",
    //     // });
    //     window.scrollTo({ top: 100, behavior: "smooth" });
    //     this.overlay = true;
    //   }
    // },
    async checkTotalSum() {
      if (
        !this.$v.$invalid &&
        (this.adultNumber || this.childNumber) &&
        this.choseAttractions &&
        !this.disabledCart
      ) {
        let attractions = JSON.parse(localStorage.attractions);
        attractions = attractions.map((object) => ({
          attraction_uuid: object.uid,
        }));
        if (this.isIncludeGDLPublicTransport) {
          attractions.push({ attraction_uuid: this.additionalAttractionUuid });
        }
        let data = {
          citypass_uuid: this.selectedCitypassId,
          quantity_adult: this.adultNumber,
          quantity_kid: this.childNumber,
          attractions: attractions,
        };
        await CartService.cartPreview(data).then((res) => {
          this.orderTotal = res.result.total;
        });
      } else {
        this.orderTotal = 0;
      }
    },
    choosedConfirm(check) {
      this.noGdlModal = false;
      if (this.typeConfirm == "cart") {
        if (check) {
          if (
            !this.isIncludeGDLPublicTransport &&
            this.isAdditionalAttraction
          ) {
            this.typeConfirm = "noBus";
            setTimeout(() => {
              this.noGdlModal = true;
            }, 200);
          } else {
            this.$emit(
              "addToCart",
              this.selectedCitypassId,
              this.date,
              this.adultNumber,
              this.childNumber,
              this.selectedCitypass.attraction_count,
              this.isIncludeGDLPublicTransport
            );
            this.selectedCitypassId = "";
            this.selectedCitypass = {};
            this.disabledCart = false;
          }
        } else {
          this.disabledCart = false;
        }
      } else {
        this.isIncludeGDLPublicTransport = check;
        this.$emit(
          "addToCart",
          this.selectedCitypassId,
          this.date,
          this.adultNumber,
          this.childNumber,
          this.selectedCitypass.attraction_count,
          this.isIncludeGDLPublicTransport
        );
        this.selectedCitypassId = "";
        this.selectedCitypass = {};
        this.disabledCart = false;
      }
      //   this.isIncludeGDLPublicTransport = check;
      //   this.noGdlModal = false;
      //   this.$emit(
      //     "addToCart",
      //     this.selectedCitypassId,
      //     this.date,
      //     this.adultNumber,
      //     this.childNumber,
      //     this.selectedCitypass.attraction_count,
      //     this.isIncludeGDLPublicTransport
      //   );
      //   this.selectedCitypassId = "";
      //   this.selectedCitypass = {};
    },
    changeStyle() {
      if (!this.mapFull) {
        this.zoom = 12;
      } else this.zoom = 10;
      this.mapFull = !this.mapFull;
      this.$emit("fullMap");
      window.scrollTo(0, 0);
      this.infoWinOpen = false;
    },
    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position;
      console.log(marker.openHours.length, marker.openHours);
      marker.openHoursFiltered = "";
      for (var i = 0; i < marker.openHours.length; i++) {
        if (marker.openHours[i].day.length > 3) {
          marker.openHoursFiltered +=
            marker.openHours[i].day.substr(0, 3) +
            "-" +
            marker.openHours[i].day.substr(
              marker.openHours[i].day.length - 3,
              marker.openHours[i].day.length
            ) +
            " " +
            marker.openHours[i].from +
            "-" +
            marker.openHours[i].to +
            "<br>";
        } else {
          marker.openHoursFiltered +=
            marker.openHours[i].day.substr(0, 3) +
            " " +
            marker.openHours[i].from +
            "-" +
            marker.openHours[i].to +
            "<br>";
        }
      }
      this.infoOptions.content =
        '<div id="map-title">' +
        marker.title +
        '</div><div id="map-address"> <img src="' +
        require("@/assets/Vector.svg") +
        '" style="width: 12px;" class="mr-1 mt-1" />' +
        marker.address +
        "</div>" +
        '<div id="map-address"> <img src="' +
        require("@/assets/Buses.svg") +
        '" style="width: 12px;" class="mr-1 mt-1" />' +
        marker.closest_stop +
        "</div>" +
        '<div id="map-address"> <img src="' +
        require("@/assets/Time.svg") +
        '" style="width: 12px;" class="mr-1 mt-1 " />' +
        marker.openHoursFiltered +
        "</div>";
      for (let i = 0; i < this.markers.length; i++) {
        if (
          marker.address == this.markers[i].address &&
          marker.id != this.markers[i].id
        ) {
          this.markers[i].openHoursFiltered = "";
          for (var j = 0; j < this.markers[i].openHours.length; j++) {
            if (this.markers[i].openHours[j].day.length > 3) {
              this.markers[i].openHoursFiltered +=
                this.markers[i].openHours[j].day.substr(0, 3) +
                "-" +
                this.markers[i].openHours[j].day.substr(
                  this.markers[i].openHours[j].day.length - 3,
                  this.markers[i].openHours[j].day.length
                ) +
                " " +
                this.markers[i].openHours[j].from +
                "-" +
                this.markers[i].openHours[j].to +
                "<br>";
            } else {
              this.markers[i].openHoursFiltered +=
                this.markers[i].openHours[j].day.substr(0, 3) +
                " " +
                this.markers[i].openHours[j].from +
                "-" +
                this.markers[i].openHours[j].to +
                "<br>";
            }
          }

          this.infoOptions.content +=
            '<br><div id="map-title">' +
            this.markers[i].title +
            '</div><div id="map-address"> <img src="' +
            require("@/assets/Vector.svg") +
            '" style="width: 12px;" class="mr-1 mt-1" />' +
            this.markers[i].address +
            "</div>" +
            '<div id="map-address"> <img src="' +
            require("@/assets/Buses.svg") +
            '" style="width: 12px;" class="mr-1 mt-1" />' +
            this.markers[i].closest_stop +
            "</div>" +
            '<div id="map-address"> <img src="' +
            require("@/assets/Time.svg") +
            '" style="width: 12px;" class="mr-1 mt-1 " />' +
            this.markers[i].openHoursFiltered +
            "</div>";
        }
      }
      //this.infoOptions.content = '<div id="map-title">' + marker.title + '</div><div id="map-address"> <img src="'+require('@/assets/Vector.svg')+'" style="width: 12px;" class="mr-1 mt-1" />' + marker.address + '</div>' + '<div id="map-address"> <img src="'+require('@/assets/Buses.svg')+'" style="width: 12px;" class="mr-1 mt-1" />' + marker.closest_stop + '</div>' +  '<div id="map-address"> <img src="'+require('@/assets/Time.svg')+'" style="width: 12px;" class="mr-1 mt-1 " />' + marker.openHoursFiltered + '</div>';

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    async checkCitypassInCart() {
      this.disabledCart = true;
      this.typeConfirm = "";
      let cart = await CartService.getCart();
      let response = cart.result.list;

      let check = false;
      response.forEach((el) => {
        if (
          el.citypass_uuid == this.selectedCitypassId &&
          el.started_at.substr(0, 10) == this.date
        ) {
          this.noGdlModal = true;
          this.typeConfirm = "cart";
          check = true;
          return;
        }
      });

      if (!check) {
        if (!this.isIncludeGDLPublicTransport && this.isAdditionalAttraction) {
          this.noGdlModal = true;
          this.typeConfirm = "noBus";
        } else {
          this.$emit(
            "addToCart",
            this.selectedCitypassId,
            this.date,
            this.adultNumber,
            this.childNumber,
            this.selectedCitypass.attraction_count,
            this.isIncludeGDLPublicTransport
          );
          this.selectedCitypassId = "";
          this.selectedCitypass = {};
          this.disabledCart = false;
        }
      }
    },

    addToCart() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.adultNumber !== 0 || this.childNumber !== 0) {
          // if (!this.isIncludeGDLPublicTransport) {
          //   this.noGdlModal = true;
          // } else {
          this.checkCitypassInCart();
          // this.$emit(
          //   "addToCart",
          //   this.selectedCitypassId,
          //   this.date,
          //   this.adultNumber,
          //   this.childNumber,
          //   this.selectedCitypass.attraction_count,
          //   this.isIncludeGDLPublicTransport
          // );
          // this.selectedCitypassId = "";
          // this.selectedCitypass = {};
          //}
        } else {
          this.$emit("errorNumber");
        }
      }
    },
    // sumOrderTotal() {
    //   if (this.isIncludeGDLPublicTransport) {
    //     this.orderTotal =
    //       this.adultNumber * this.selectedCitypass.price_adult +
    //       this.childNumber * this.selectedCitypass.price_kid +
    //       this.adultNumber * this.transportAttractionPriceAdult +
    //       this.childNumber * this.transportAttractionPriceChild;
    //     this.bookingFee;
    //   } else {
    //     this.orderTotal =
    //       this.adultNumber * this.selectedCitypass.price_adult +
    //       this.childNumber * this.selectedCitypass.price_kid +
    //       this.bookingFee;
    //   }
    // },
    setUserLocale() {
      this.userLocale = this.loggedUser
        ? this.loggedUser.locale
        : this.user.locale;
    },
    setFillCitypass() {
      if (
        Object.values(this.selectedCitypass).length > 0 &&
        (this.adultNumber > 0 || this.childNumber > 0) &&
        this.date !== ""
      ) {
        this.isChooseCitypass = true;
        this.$emit(
          "chooseCitypass",
          this.date,
          this.endDate,
          this.adultNumber,
          this.childNumber
        );
      } else {
        this.isChooseCitypass = false;
        this.$emit("removeCitypass");
      }
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
    dateFormat() {
      let date = new Date(this.date);
      return (
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      );
    },
    selectedCitypassIdError() {
      const errors = [];
      if (!this.$v.selectedCitypassId.$dirty) {
        return errors;
      }
      !this.$v.selectedCitypassId.required && errors.push("");
      return errors;
    },
    dateError() {
      const errors = [];
      if (!this.$v.date.$dirty) {
        return errors;
      }
      !this.$v.date.required && errors.push("");
      return errors;
    },
  },
  watch: {
    // overlay: {
    //   handler() {
    //     if (!this.$vuetify.breakpoint.smAndDown) {
    //       this.$emit("changedOverlay", this.overlay);
    //     }
    //   },
    // },
    selectSnackbar: {
      deep: true,
      handler() {
        console.log(this.selectSnackbar);
        this.$emit("changedSelectSnackbar", this.selectSnackbar);
      },
    },
    choseAttractions: {
      handler() {
        if (this.choseAttractions && this.$vuetify.breakpoint.smAndDown) {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
        this.selectSnackbar.used = false;
        this.checkTotalSum();
      },
    },
    "loggedUser.locale": {
      deep: true,
      handler() {
        if (this.selectedCitypassId) {
          let discount = [];
          this.selectedCitypass.attractions.forEach((attraction) => {
            if (attraction.discount !== null) {
              discount.push(attraction.discount);
            }
          });
          this.$emit(
            "selectedCitypassAttraction",
            this.selectedCitypass.attractions,
            this.selectedCitypass.attraction_count,
            Math.min(...discount),
            Math.max(...discount)
          );
        }
        this.setUserLocale();
      },
    },
    "user.locale": {
      deep: true,
      handler() {
        if (this.selectedCitypassId) {
          let discount = [];
          this.selectedCitypass.attractions.forEach((attraction) => {
            if (attraction.discount !== null) {
              discount.push(attraction.discount);
            }
          });
          this.$emit(
            "selectedCitypassAttraction",
            this.selectedCitypass.attractions,
            this.selectedCitypass.attraction_count,
            Math.min(...discount),
            Math.max(...discount)
          );
        }
        this.setUserLocale();
      },
    },
    date: {
      deep: true,
      handler() {
        if (!localStorage.date) {
          this.checkTotalSum();
        }
        if (this.date) {
          localStorage.date = this.date;
        }

        this.isClose = true;
        if (this.selectedCitypass && this.date) {
          var endDate = new Date(this.date);
          if (
            this.selectedCitypass.days &&
            this.selectedCitypass.days != "1,2,3,4,5,6,7"
          ) {
            let remainderDays = 0;
            remainderDays =
              this.selectedCitypass.days.substr(-1) -
              new Date(this.date).getDay();
            endDate.setDate(endDate.getDate() + parseInt(remainderDays));

            if (remainderDays == 7) {
              remainderDays = 0;
            }

            if (
              parseInt(remainderDays) + 1 <
              parseInt(this.selectedCitypass.term)
            ) {
              this.$emit("show_snackbar", remainderDays);
            }
          } else {
            endDate.setDate(
              endDate.getDate() + parseInt(this.selectedCitypass.term) - 1
            );
          }
          this.endDate = endDate;
        }
        if (this.date && this.endDate) {
          this.setFillCitypass();
        }
      },
    },
    "this.$refs.map.$el.clientWidth": {
      deep: true,
      handler() {
        console.log("resize");
      },
    },
    isSuccess: {
      deep: true,
      handler() {
        if (this.isSuccess == true) {
          this.selectedCitypassId = "";
          this.adultNumber = 0;
          this.childNumber = 0;
          this.date = "";
          this.$v.$reset();
        }
      },
    },
    adultNumber: {
      deep: true,
      handler() {
        if (this.adultNumber > 17) {
          this.adultNumber = 17;
          this.$emit();
        }
        if (this.adultNumber) {
          localStorage.adultNumber = this.adultNumber;
        }
        if (this.selectedCitypassId !== "") {
          this.checkTotalSum();
        }
        this.setFillCitypass();
      },
    },
    childNumber: {
      deep: true,
      handler() {
        if (this.childNumber) {
          localStorage.childNumber = this.childNumber;
        }
        if (this.selectedCitypassId !== "") {
          this.checkTotalSum();
        }
        this.setFillCitypass();
      },
    },
    orderTotal: {
      deep: true,
      handler() {
        if (this.orderTotal < 0) {
          this.orderTotal = 0;
        }
      },
    },
    citypassList: {
      deep: true,
      handler() {
        if (this.citypassList.length > 0) {
          if (localStorage.selectedCitypassId) {
            this.selectedCitypassId = localStorage.selectedCitypassId;
          }
        }
      },
    },
    isIncludeGDLPublicTransport: {
      handler() {
        if (this.isIncludeGDLPublicTransport) {
          localStorage.isIncludeGDLPublicTransport =
            this.isIncludeGDLPublicTransport;
        } else {
          localStorage.removeItem("isIncludeGDLPublicTransport");
        }
        if (this.selectedCitypassId !== "") {
          this.checkTotalSum();
        }
      },
    },
    selectedCitypassId: {
      deep: true,
      handler() {
        if (this.selectedCitypassId !== "") {
          this.selectedCitypass = this.citypassList.find(
            (cityPass) => cityPass.uuid === this.selectedCitypassId
          );
          let discount = [];
          this.selectedCitypass.attractions.forEach((attraction) => {
            if (attraction.discount !== null) {
              discount.push(attraction.discount);
            }
          });
          this.$emit(
            "selectedCitypassAttraction",
            this.selectedCitypass.attractions,
            this.selectedCitypass.attraction_count,
            Math.min(...discount),
            Math.max(...discount)
          );
        } else {
          this.selectedCitypass = [];
          this.endDate = null;
        }
        if (localStorage.selectedCitypassId != this.selectedCitypassId) {
          this.adultNumber = 0;
          this.childNumber = 0;
          this.orderTotal = 0;
          this.date = "";
          this.endDate = null;
          this.allowedDates = "";
          this.isIncludeGDLPublicTransport = false;
          this.selectSnackbar.used = false;
          this.selectSnackbar.visible = false;

          if (localStorage.selectedCitypassId) {
            localStorage.removeItem("selectedCitypassId");
          }

          if (localStorage.date) {
            localStorage.removeItem("date");
          }

          if (localStorage.adultNumber) {
            localStorage.removeItem("adultNumber");
          }

          if (localStorage.childNumber) {
            localStorage.removeItem("childNumber");
          }

          if (localStorage.isIncludeGDLPublicTransport) {
            localStorage.removeItem("isIncludeGDLPublicTransport");
          }

          if (localStorage.attractions) {
            localStorage.removeItem("attractions");
          }

          if (localStorage.checkedNum) {
            localStorage.removeItem("checkedNum");
          }
          if (localStorage.booking_attractions) {
            localStorage.removeItem("booking_attractions");
          }
        } else {
          if (localStorage.date) {
            this.date = localStorage.date;
          }

          if (localStorage.adultNumber) {
            this.adultNumber = localStorage.adultNumber;
          }

          if (localStorage.childNumber) {
            this.childNumber = localStorage.childNumber;
          }

          if (localStorage.isIncludeGDLPublicTransport) {
            this.isIncludeGDLPublicTransport =
              localStorage.isIncludeGDLPublicTransport;
          }
        }
        if (
          this.selectedCitypass.days &&
          this.selectedCitypass.days != "1,2,3,4,5,6,7"
        ) {
          this.allowedDates = (val) =>
            (new Date(val).getDay() >=
              this.selectedCitypass.days.substr(0, 1) &&
              new Date(val).getDay() <=
                this.selectedCitypass.days.substr(-1)) ||
            (new Date(val).getDay() == 0 &&
              this.selectedCitypass.days.substr(-1) == "7");
          // new Date(val).getDay() ===
          // parseInt(this.selectedCitypass.days.substr(0, 1));
        }
        this.$v.$reset();

        if (this.selectedCitypassId) {
          localStorage.selectedCitypassId = this.selectedCitypassId;
        }
        this.setFillCitypass();
      },
    },
    endDate: {
      handler() {
        if (this.date && this.endDate) {
          this.setFillCitypass();
        }
      },
    },
  },
};
</script>

<style>
#map-address {
  font-size: 12px;
  font-weight: 400;
  color: rgb(0, 0, 0);
  line-height: 1;
}

#map-title {
  font-size: 18px;
  font-weight: 500;
  color: #e62076;
}

.gm-style-iw {
  min-width: 180px !important;
  min-height: 100px;
}

.blink_me {
  animation: blinker 2s linear infinite;
}

.v-menu__content {
  background-color: white;
}

.fullsize {
  position: "fixed";
  top: 0;
  left: 0;
  width: "100%";
  height: "100%";
  background-color: "white";
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
