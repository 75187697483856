<template>
  <v-dialog :retain-focus="false" v-model="attractionModal" persistent max-width="1200px">
    <v-card>
      <div v-if="showLoader" style="height: 400px">
        <v-row no-gutters align="center" justify="center">
          <v-progress-circular
            class="mx-auto"
            style="margin-top: 180px"
            indeterminate
            color="#e62076"
          ></v-progress-circular>
        </v-row>
        <v-row class="mt-5" no-gutters align="center" justify="center">
          <div
            style="color: #e62076; opacity: 0.75"
            class="text-h5 blink"
            v-if="uploadPercentage != 0 && uploadPercentage != 100"
          >
            {{ uploadPercentage }}%
          </div>
          <div
            style="color: #e62076; opacity: 0.75"
            class="text-h5 blink"
            v-if="uploadPercentage == 100"
          >
            {{ "attractions_almost_done_label" | localize }}
          </div>
        </v-row>
      </div>
      <v-stepper v-model="el" v-show="!showLoader">
        <v-stepper-header>
          <v-stepper-step :complete="el > 1" step="1" color="#E62076">
            {{ "attraction_label_information_en" | localize }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="el > 2" step="2" color="#E62076">
            {{ "attraction_label_information_es" | localize }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="el > 3" step="3" color="#E62076">{{
            "attractions_label_location" | localize
          }}</v-stepper-step>

          <v-divider></v-divider>
          <v-stepper-step :complete="el > 4" step="4" color="#E62076">{{
            "attractions_label_open_hours" | localize
          }}</v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="el > 5" step="5" color="#E62076">
            {{ "attractions_label_details" | localize }}</v-stepper-step
          >
          <v-divider></v-divider>

          <v-stepper-step step="6" color="#E62076">
            {{ "attractions_refund_booking_page" | localize }}</v-stepper-step
          >
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <add-info-en @close_modal="closeModal" @next="nextEN" />
          </v-stepper-content>

          <v-stepper-content step="2">
            <add-info-es @close_modal="closeModal" @next="nextES" />
          </v-stepper-content>

          <v-stepper-content step="3">
            <add-location @close_modal="closeModal" @next="nextLocation" />
          </v-stepper-content>

          <v-stepper-content step="4"
            ><add-hours @close_modal="closeModal" @next="nextHours" />
          </v-stepper-content>

          <v-stepper-content step="5">
            <add-images @close_modal="closeModal" @next="nextImages" />
          </v-stepper-content>
          <v-stepper-content step="6">
            <add-refunds-and-booking @close_modal="closeModal" @next="create" />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper> </v-card
  ></v-dialog>
</template>

<script>
import addImages from "./addAtractions/addImages.vue";
import addLocation from "./addAtractions/addLocation.vue";
import addHours from "./addAtractions/addHours.vue";
import addInfoEs from "./addAtractions/addInfoES.vue";
import addInfoEn from "./addAtractions/addInfoEN.vue";
//import requestFormData from "@/requests/toFormData";
import store from "@/store";
import axios from "axios";
import AddRefundsAndBooking from "./addAtractions/addRefundsAndBooking.vue";
import cityMixin from "@/mixins/cityMixin";

export default {
  mixins: [cityMixin],
  components: {
    addImages,
    addLocation,
    addInfoEn,
    addInfoEs,
    addHours,
    AddRefundsAndBooking,
  },
  data: () => ({
    el: 1,
    uploadPercentage: 0,
    attractionModal: true,
    showLoader: true,
    attraction: {
      content: {
        en: {},
        es: {},
      },
    },
  }),
  watch: {
    attractionModal: {
      handler() {
        this.attractionModal = false;
        this.$emit("close_modal");
      },
    },
  },
  mounted() {
    this.el = 1;
    setTimeout(this.hideLoader, 500);
  },
  methods: {
    async create(
      // closest_stop,
      // price,
      // child_price,
      // business,
      // first_image,
      // second_image,
      // third_image,
      // four_image,
      condition_en,
      condition_es,
      booking_es,
      booking_en
      // status
    ) {
      // console.log(closest_stop);
      // this.attraction.images = [
      //   { image: first_image, alt: "Image", sort: 0 },
      //   { image: second_image, alt: "Image", sort: 0 },
      //   { image: third_image, alt: "Image", sort: 0 },
      //   { image: four_image, alt: "Image", sort: 0 },
      // ];
      // this.attraction.closest_stop = closest_stop;
      // this.attraction.price = price;
      // this.attraction.child_price = child_price;
      this.attraction.content.en.booking_requirements = booking_en;
      this.attraction.content.es.booking_requirements = booking_es;
      this.attraction.content.en.conditions = condition_en;
      this.attraction.content.es.conditions = condition_es;
      //this.attraction.booking_requirements = true

      // this.attraction.status = status;
      // this.attraction.business_uid = business;
      //this.attraction.booking_fee = booking_fee == true ? "1" : "0";
      //let form = requestFormData.jsonToFormData(this.attraction);
      console.log(this.attraction);
      this.showLoader = true;
      axios
        .post(
          process.env.VUE_APP_API + `citypass/attraction`,
          this.attraction,
          {
            headers: {
              Authorization: `Bearer ${store.getters.loggedUser.token}`,
              "X-CITYPASS-KEY": `${process.env.VUE_APP_X_API_KEY}`,
            },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this),
          }
        )
        .then((res) => {
          this.showLoader = false;
          if (!res?.data.result) {
            alert(res?.data.messages.error);
          } else {
            this.attractionModal = false;
            this.$emit("addedAtraction");
          }
        });
    },
    nextEN(
      title,
      short_description,
      description
      //refund_policy
    ) {
      this.el = 2;
      this.attraction.content.en.title = title;
      this.attraction.content.en.short_description = short_description;
      this.attraction.content.en.description = description;
      // this.attraction.content.en.refund_policy = refund_policy;
    },
    nextES(
      title,
      short_description,
      description
      //refund_policy
    ) {
      this.el = 3;
      this.attraction.content.es.title = title;
      this.attraction.content.es.short_description = short_description;
      this.attraction.content.es.description = description;
      // this.attraction.content.es.refund_policy = refund_policy;
    },
    nextLocation(marker, address) {
      this.attraction.address = address;
      this.attraction.geo_lat = marker.lat;
      this.attraction.geo_lon = marker.lng;
      this.el = 4;
    },
    nextHours(days) {
      let working_days = {};
      let working_hours = {};
      for (let i = 0; i < days.length; i++) {
        if (days[i].checked) {
          working_days[`${days[i].name}`] = "on";
          working_hours[`${days[i].name}`] = {
            from: days[i].from,
            to: days[i].to,
          };
        }
      }
      this.attraction.working_days = working_days;
      this.attraction.working_hours = working_hours;
      console.log(this.attraction);
      this.el = 5;
    },
    nextImages(
      closest_stop,
      price,
      child_price,
      business,
      first_image,
      second_image,
      third_image,
      four_image,
      category,
      status,
      is_booking_require,
      is_visible,
      max_discount_attraction,
      report_email
    ) {
      this.attraction.closest_stop = closest_stop;
      this.attraction.price = price;
      this.attraction.child_price = child_price;
      this.attraction.business_uid = business;
      this.attraction.is_visible = is_visible
      this.attraction.maximum_available_discount = max_discount_attraction
      this.attraction.report_email = report_email;
      this.attraction.is_booking_require = is_booking_require
      if (this.mazatlan) {
        this.attraction.category = category;
      }
      this.attraction.images = [
        { image: first_image, alt: "Image", sort: 0 },
        { image: second_image, alt: "Image", sort: 0 },
        { image: third_image, alt: "Image", sort: 0 },
        { image: four_image, alt: "Image", sort: 0 },
      ];
      console.log(status);
      this.attraction.status = status;
      this.el = 6;
    },

    closeModal() {
      this.attractionModal = false;
    },
    next(el) {
      console.log(el);
      this.el = el;
    },
    hideLoader() {
      this.showLoader = false;
    },
  },
};
</script>
<style scoped>
</style>