<template>
  <v-dialog v-model="termsModal" width="600px">
    <v-card color="white" class="black--text">
      <v-card-title>{{
        "attractions_prebooking_window_title" | localize
      }}</v-card-title>
      <v-card-text>
        <div v-html="text"></div>
        <v-row
          align="center"
          class="font-weight-bold mt-3 mx-1"
          style="font-size: 17px"
        >
          <v-icon color="error" class="mr-2">mdi-alert</v-icon
          ><span>{{ "attractions_prebooking_alert" | localize }}</span>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="mb-0 mx-1 justify-center">
          <v-spacer />
          <v-col class="col-3">
            <v-btn block outlined @click="termsModal = false" color="#e62076">{{
              "button_no" | localize
            }}</v-btn>
          </v-col>
          <v-col class="col-3">
            <v-btn block dark @click="$emit('onConfirm')" color="#e62076">{{
              "button_yes" | localize
            }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    termsModal: true,
  }),
  props: {
    text: {
      require: false,
    },
  },
  watch: {
    termsModal: {
      handler() {
        this.termsModal = false;
        this.$emit("close_modal");
      },
    },
  },
};
</script>