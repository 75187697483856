<template>
  <v-main>
    <v-container>
      <our-attractions-component @openCart="$emit('openCart')" v-if="!mazatlan"/>
      <our-attractions-mazatlan @openCart="$emit('openCart')" v-if="mazatlan"/>
    </v-container>
  </v-main>
</template>

<script>
import OurAttractionsComponent from "../components/OurAttractions/OurAttractionsComponent.vue";
import OurAttractionsMazatlan from "../components/OurAttractions/mazatlan/OurAttractionsMazatlan.vue";
import cityMixin from '@/mixins/cityMixin'

export default {
  mixins: [cityMixin],
  components: {
    OurAttractionsComponent,
    OurAttractionsMazatlan,
  },
};
</script>

<style>
</style>>