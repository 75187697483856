<template>
  <v-dialog v-model="visibility" scrollable max-width="1000px" style="height: 500px;">
    <v-card>
      <v-card-title> </v-card-title>
      <div v-if="loader" class="d-flex align-center" style="min-height: 600px">
        <v-progress-circular
          v-if="loader"
          class="mx-auto"
          indeterminate
          color="#E62076"
        ></v-progress-circular>
      </div>
      <v-card-text v-if="!loader">
        <v-row>
          <draggable v-model="attractions" class="row wrap">
            <v-col
              class="col-6 col-sm-4 col-lg-3 text-center"
              justify="center"
              align="center"
              v-for="(attraction, index) in attractions"
              :key="index"
            >
              <v-card style="height: 100%; cursor: pointer">
                <v-img
                  height="100px"
                  class="mx-auto"
                  :src="attraction.images[0].thumbs[2]"
                />
                <v-row
                  align="center"
                  justify="center"
                  style="font-size: 12px; line-height: 14px"
                  class="pa-2 font-weight-bold"
                  no-gutters
                >
                  {{ attraction.title }}
                </v-row>
              </v-card>
            </v-col>
          </draggable>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="!loader">
        <v-btn text class="ml-auto" @click="$emit('close')"
          >{{ "button_cancel" | localize }}
        </v-btn>
        <v-btn dark color="#E62076" @click="saveOrder"
          >{{ "button_save" | localize }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import AttractionsService from "@/requests/Attractions/AttractionsService";
import draggable from "vuedraggable";

export default {
  mixins: [modalMixin],
  components: {
    draggable,
  },
  data: () => ({
    attractions: [],
    loader: false,
  }),
  mounted() {
    this.getAttractions();
  },
  methods: {
    async getAttractions() {
      this.loader = true;
      await AttractionsService.getAttractions().then((res) => {
        this.attractions = res.result.filter(el => el.status == 'Active');
        this.loader = false;
      });
    },
    async saveOrder() {
      this.loader = true;
      const newList = [];
      this.attractions.forEach((attraction, index) => {
        newList.push({ uid: attraction.uid, order: index });
      });
      await AttractionsService.updateAttractionOrder({
        order: [...newList],
      }).then(() => {
        this.loader = false;
        this.$emit("success");
      });
    },
  },
};
</script>