<template>
  <div v-scroll="onScroll" style="position: relative">
    <snackbar-success
      v-if="snackbar"
      :snackbar="snackbar"
      :snackbarText="snackbarText"
      :boldText="true"
    />
    <v-snackbar
      top
      timeout="8000"
      v-model="choseAttractionSnackbarSuccess"
      color="success"
    >
      <v-row align="center">
        <v-icon class="mx-3">mdi-alert-circle-outline</v-icon>
        <v-col>
          <span
            class="font-weight-bold"
            :style="$vuetify.breakpoint.smAndDown ? '' : 'font-size: 16px'"
            >{{ "attractions_selected_alert" | localize }}
            <span v-if="min_discount_attraction !== max_discount_attraction">
              {{
                (min_discount_attraction * 100).toFixed(2).replace(/\.00$/, "")
              }}%
              {{ "attractions_selected_alert_2" | localize }}
            </span>

            {{
              (max_discount_attraction * 100).toFixed(2).replace(/\.00$/, "")
            }}% {{ "attractions_selected_alert_3" | localize }}</span
          >
        </v-col>
      </v-row></v-snackbar
    >
    <v-snackbar
      top
      v-model="dateSnackbar"
      :style="choseAttractionSnackbarSuccess ? 'top: 80px' : ''"
      color="deep-orange darken-1"
    >
      <v-row align="center">
        <v-icon class="mx-3">mdi-alert</v-icon>
        <v-col>
          <span>
            {{ "attractions_calendar_reminder_popup1" | localize }}
            {{ remainderDays + 1 }}
            {{ "attractions_calendar_reminder_popup2" | localize }}</span
          >
        </v-col>
      </v-row>
    </v-snackbar>
    <v-snackbar
      centered
      v-model="selectSnackbar.visible"
      color="deep-orange darken-1"
      timeout="10000"
      style="z-index: 7 !important"
    >
      <v-row align="center">
        <v-icon class="mx-3">mdi-alert</v-icon>
        <v-col>
          <span class="font-weight-bold">
            {{ "attractions_selected_label1" | localize }}
            <br />
            {{ "attractions_selected_label" | localize }}
          </span>
        </v-col>
      </v-row>
    </v-snackbar>
    <v-row align="center" class="px-3 mt-1 mb-10">
      <h1>{{ "header_our_attractions_part1" | localize }}</h1>
      <v-divider class="mt-5 ml-3" />
    </v-row>
    <v-btn
      v-if="admin"
      fab
      color="#fff"
      right
      dark
      small
      top
      absolute
      style="margin-top: 35px; margin-right: 50px"
      @click="orderingModal = true"
    >
      <v-icon color="#E62076">mdi-swap-vertical</v-icon>
    </v-btn>
    <v-btn
      v-if="admin"
      fab
      color="#E62076"
      right
      dark
      small
      top
      absolute
      style="margin-top: 35px"
      @click="openModal"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-col
      v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
      class="px-0 py-0"
    >
      <v-row>
        <v-col cols="3" xl="3" lg="3" md="4" sm="8">
          <OurAttractionsBuyForm
            :citypassList="citypassList"
            :isSuccess="snackbarSuccess"
            :markers="markers"
            :choseAttractions="choseAttractions"
            :isAdditionalAttraction="isAdditionalAttraction"
            :transportAttractionPriceAdult="transportAttractionPriceAdult"
            :transportAttractionPriceChild="transportAttractionPriceChild"
            :additionalAttractionUuid="additionalAttractionUuid"
            :bookingEvents="bookingEvents"
            @selectedCitypassAttraction="setAttractionList"
            @addToCart="addToCart"
            @errorNumber="numberSnackbar = true"
            @show_snackbar="setSnackbar"
            @changedSelectSnackbar="changedSelectSnackbar"
            @deleteAttraction="deleteAttraction"
            @chooseCitypass="setIsChooseCitypass"
            @removeCitypass="isChoseCitipass = false"
            :style="'position:' + position + ';top:' + top + 'px'"
            @changedOverlay="changedOverlay"
            @fullMap="isFullMap = !isFullMap"
            style="z-index: 10"
          />
        </v-col>
        <v-col
          cols="9"
          class="pt-0"
          :style="fullMap ? 'z-index: 7;overflow-y:hidden;' : ''"
        >
          <AttractionsList
            class="ml-5"
            ref="list"
            :attractionsList="attractionsList"
            @selectAttraction="setSelectAttraction"
            @setBookingEvents="setBookingEvents"
            :isChoseCitipass="isChoseCitipass"
            :citypassFreeActtraction="free_attraction_count"
            :key="attractionListKey"
            :startCitypassDate="startCitypassDate"
            :endCitypassDate="endCitypassDate"
            :adultNum="adultNum"
            :childNum="childNum"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-row v-else justify="center">
      <v-col>
        <v-row justify="center">
          <OurAttractionsBuyForm
            :citypassList="citypassList"
            :isSuccess="snackbarSuccess"
            :markers="markers"
            :choseAttractions="choseAttractions"
            :isAdditionalAttraction="isAdditionalAttraction"
            :transportAttractionPriceAdult="transportAttractionPriceAdult"
            :transportAttractionPriceChild="transportAttractionPriceChild"
            :additionalAttractionUuid="additionalAttractionUuid"
            :bookingEvents="bookingEvents"
            @selectedCitypassAttraction="setAttractionList"
            @addToCart="addToCart"
            @errorNumber="numberSnackbar = true"
            @chooseCitypass="setIsChooseCitypass"
            @removeCitypass="isChoseCitipass = false"
            @changedSelectSnackbar="changedSelectSnackbar"
          />
        </v-row>
        <AttractionsList
          ref="list"
          style="margin-top: 35px"
          :attractionsList="attractionsList"
          @selectAttraction="setSelectAttraction"
          @setBookingEvents="setBookingEvents"
          :isChoseCitipass="isChoseCitipass"
          :citypassFreeActtraction="free_attraction_count"
          :key="attractionListKey"
          :startCitypassDate="startCitypassDate"
          :endCitypassDate="endCitypassDate"
          :adultNum="adultNum"
          :childNum="childNum"
        />
      </v-col>
    </v-row>
    <ordering-modal
      v-if="orderingModal"
      :visible="orderingModal"
      @success="
        orderingModal = false;
        getAttractions();
      "
      @close="orderingModal = false"
    />
    <add-attraction-modal
      ref="modal"
      v-if="attractionModal"
      @close_modal="closeModal"
      @addedAtraction="
        attractionModal = false;
        getAttractions();
      "
    />
  </div>
</template>

<script>
import OurAttractionsBuyForm from "./OurAttractionsBuyForm.vue";
import AttractionsList from "./AttractionsList.vue";
import CitypassService from "../../requests/Citypass/CitypassService";
import addAttractionModal from "./addAtractionModal.vue";
import CartService from "../../requests/Cart/CartService";
import AttractionService from "@/requests/Attractions/AttractionsService.js";
import snackbarSuccess from "../UI/snackbarSuccess.vue";
import { mapGetters } from "vuex";
import userSettingsMixin from "@/mixins/userSettingsMixin";
import orderingModal from "./orderingModal.vue";

export default {
  components: {
    OurAttractionsBuyForm,
    AttractionsList,
    addAttractionModal,
    snackbarSuccess,
    orderingModal,
  },
  mixins: [userSettingsMixin],
  data: () => ({
    citypassList: [],
    attractionsList: [],
    selectedAttraction: [],
    working_hoursValues: [],
    markers: [],
    attractionModal: false,
    snackbar: false,
    snackbarSuccess: false,
    isChoseCitipass: false,
    numberSnackbar: false,
    choseAttractions: false,
    isCart: false,
    choseAttractionSnackbarSuccess: false,
    isAdditionalAttraction: false,
    fullMap: false,
    additionalAttractionUuid: "",
    snackbarText: "",
    free_attraction_count: "",
    startCitypassDate: "",
    endCitypassDate: "",
    attractionListKey: 1,
    min_discount_attraction: 0,
    max_discount_attraction: 0,
    transportAttractionPriceAdult: 0,
    transportAttractionPriceChild: 0,
    dateSnackbar: false,
    remainderDays: null,
    orderingModal: false,
    top: 198,
    selectSnackbar: {
      visible: false,
      used: false,
    },
    position: "fixed",
    citypassAttractions: [],
    adultNum: 0,
    childNum: 0,
    bookingEvents: [],
  }),
  mounted() {
    this.getCitypasses();
    this.getAttractions();
    this.$vuetify.goTo(0, 0);
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      if (top >= 198) {
        this.top = 30;
      } else if (top == 0) {
        this.top = 198;
      } else {
        this.top = 198 - top;
      }
    },
    deleteAttraction(events) {
      this.$refs.list.deleteFromBuyForm(events);
    },
    setBookingEvents(events) {
      console.log(events);
      this.bookingEvents = events;
    },
    getCart(count) {
      console.log(count);
    },
    changedSelectSnackbar(snackbar) {
      console.log("snackbar", snackbar);
      this.selectSnackbar = snackbar;
    },
    changedOverlay(overlay) {
      if (overlay) {
        this.position = "static";
      } else {
        this.position = "fixed";
      }
    },
    setSnackbar(count) {
      this.remainderDays = count;
      this.dateSnackbar = true;
      setTimeout(() => {
        this.dateSnackbar = false;
      }, 6000);
    },
    closeCart() {
      this.snackbar = false;
      this.snackbarText = "";
      this.isCart = false;
    },
    async getAttractions() {
      if (this.attractionModal) {
        this.attractionModal = false;
      }
      if (!localStorage.citypass_uuid && !localStorage.selectedCitypassId) {
        await AttractionService.getAttractions().then((res) => {
          this.filterAttractions(res.result);
        });
      }
    },
    filterAttractions(attractions) {
      this.attractionsList = attractions;
      if (!this.admin) {
        this.attractionsList = this.attractionsList.filter(
          (attr) => attr.status == "Active"
        );
      }
      let filteredWorkingHours = [];
      this.markers = [];

      for (let i = 0; i < this.attractionsList.length; i++) {
        if (typeof this.attractionsList[i].working_hours == "object") {
          filteredWorkingHours.push(this.attractionsList[i]);
        }
      }

      this.attractionsList = filteredWorkingHours;
      for (let index = 0; index < this.attractionsList.length; index++) {
        this.working_hoursValues = Object.values(
          this.attractionsList[index].working_hours
        );
        let working_hoursKey = Object.keys(
          this.attractionsList[index].working_hours
        );
        let working_hours = [];

        for (let i = 0; i < this.working_hoursValues.length; i++) {
          working_hours.push({
            day: working_hoursKey[i],
            from: this.working_hoursValues[i].from,
            to: this.working_hoursValues[i].to,
          });
        }

        let result = [];
        let currentGroup = [working_hours[0]];

        for (let i = 1; i < working_hours.length; i++) {
          // Check if days are consecutive and have the same working hours
          if (
            working_hours[i].from === currentGroup[0].from &&
            working_hours[i].to === currentGroup[0].to &&
            this.areDaysConsecutive(
              currentGroup[currentGroup.length - 1].day,
              working_hours[i].day
            )
          ) {
            // Add day to the current group
            currentGroup.push(working_hours[i]);
          } else {
            if (currentGroup.length > 1) {
              // If there is more than one day in the group, combine them and add to the result
              result.push({
                day: this.combineConsecutiveDays(currentGroup),
                from: currentGroup[0].from,
                to: currentGroup[0].to,
              });
            } else {
              // If there is only one day in the group, add it to the result
              result.push(currentGroup[0]);
            }
            currentGroup = [working_hours[i]]; // Start a new group
          }
        }

        if (currentGroup.length > 1) {
          result.push({
            day: this.combineConsecutiveDays(currentGroup),
            from: currentGroup[0].from,
            to: currentGroup[0].to,
          });
        } else {
          result.push(currentGroup[0]);
        }

        console.log("result", result);
        this.attractionsList[index].working_hours = result;

        // Create markers based on grouped working hours
        this.markers.push({
          id: this.attractionsList[index].uid,
          address: this.attractionsList[index].address,
          title: this.attractionsList[index].title,
          closest_stop: this.attractionsList[index].closest_stop,
          openHours: this.attractionsList[index].working_hours,
          position: {
            lat: parseFloat(this.attractionsList[index].geo_lat),
            lng: parseFloat(this.attractionsList[index].geo_lon),
          },
        });
      }
    },
    // Check if two days of the week are consecutive
    areDaysConsecutive(day1, day2) {
      const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
      return (
        daysOfWeek.indexOf(day1) + 1 === daysOfWeek.indexOf(day2) ||
        daysOfWeek.indexOf(day1) - 6 === daysOfWeek.indexOf(day2)
      );
    },

    // Combine consecutive days into a single string
    combineConsecutiveDays(group) {
      return group.map((day) => day.day).join("-");
    },
    closeModal() {
      this.attractionModal = false;
    },
    openModal() {
      this.attractionModal = true;
    },
    async getCitypasses() {
      let response = await CitypassService.getCitypasses();
      let citypassList = response.result;

      citypassList = citypassList.filter((el) => {
        return el.status == "1";
      });
      let localList = [...citypassList].filter(function (el) {
        return el.type == "1";
      });

      let touristsList = [...citypassList].filter(function (el) {
        return el.type == "0";
      });

      let cityPassFiltered = [];
      cityPassFiltered.push({
        header: this.$options.filters.localize("city_passes_tourists"),
      });
      cityPassFiltered = cityPassFiltered.concat(touristsList);

      cityPassFiltered.push({
        header: this.$options.filters.localize("city_passes_locals"),
      });
      cityPassFiltered = cityPassFiltered.concat(localList);

      this.citypassList = cityPassFiltered;
    },
    async setAttractionList(
      attractions,
      free_attraction_count,
      min_discount_attraction,
      max_discount_attraction
    ) {
      this.citypassAttractions = attractions;
      this.choseAttractions = false;
      this.snackbar = false;
      console.log(min_discount_attraction, max_discount_attraction);
      this.free_attraction_count = free_attraction_count;
      //Clear old attractions list
      while (this.attractionsList.length > 0) {
        this.attractionsList.pop();
      }
      this.attractionListKey++;
      this.attractionsList = [];
      let list = [];
      //Clear old citypass uuid in local storage
      localStorage.removeItem("citypass_uuid");
      let uids = [];
      let includeAdditionalAttraction = [];
      //Check if exist additonal attraction
      includeAdditionalAttraction = attractions.filter(
        (attraction) => attraction.is_additional == 1
      );
      //Set isAdditionalAttraction for show in buy form field
      this.isAdditionalAttraction = includeAdditionalAttraction.length
        ? true
        : false;
      //Cycle for get attraction uuid
      for (let i = 0; i < attractions.length; i++) {
        if (
          attractions[i].is_free !== "1" &&
          attractions[i].is_additional !== "1"
        ) {
          //If attraction not additional and not free
          uids.push(attractions[i].uuid);
        } else if (attractions[i].is_additional == "1") {
          //Set data additonal transport
          this.additionalAttractionUuid = attractions[i].uuid;
          this.transportAttractionPriceAdult = parseInt(
            attractions[i].price_adult
          );
          this.transportAttractionPriceChild = parseInt(
            attractions[i].price_kid
          );
        }
      }
      //Uids to sting
      let uidsStr = String(uids);
      //Get attraction by uids
      if (uids.length > 0) {
        await AttractionService.getAttractionsByUids(uidsStr).then((res) => {
          if (res.result !== null) {
            list = res.result;
            //Filter for only active attraction
            list = list.filter((attraction) => attraction.status == "Active");
          }
        });
      }
      list.forEach((el) => {
        el.price_kid =
          attractions.filter((attr) => attr.uuid == el.uid)[0].price_kid || 0;
        el.price_adults =
          attractions.filter((attr) => attr.uuid == el.uid)[0].price_adult || 0;
      });
      //Markers array for show marker in map
      this.markers = [];
      for (let i = 0; i < list.length; i++) {
        this.markers.push({
          lat: parseFloat(list[i].geo_lat),
          lng: parseFloat(list[i].geo_lon),
        });
      }
      //Sort attraction by title
      if (list.length > 0) {
        list = list.sort(function (a, b) {
          var textA = a.title.toUpperCase();
          var textB = b.title.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        //Filter attraction for open hours
        this.filterAttractions(list);
      } else {
        //Added crutch in order for the watcher in the component AttractionsList.vue to work
        this.attractionsList.push(1);
        setTimeout(() => {
          this.attractionsList = [];
        }, 100);
      }
      //this.isChoseCitipass = true;
    },
    async addToCart(
      uuid,
      date,
      quantity_adult,
      quantity_kid,
      free_attraction_count,
      includeGDLPublicTransport
    ) {
      this.choseAttractionSnackbarSuccess = false;
      this.snackbar = false;
      this.free_attraction_count = free_attraction_count;
      let selectedAttractionUid = [];
      if (includeGDLPublicTransport == true) {
        selectedAttractionUid.push({
          attraction_uuid: this.additionalAttractionUuid,
        });
      }
      for (let i = 0; i < this.selectedAttraction.length; i++) {
        if (this.selectedAttraction[i]?.bookingEventUid) {
          selectedAttractionUid.push({
            attraction_uuid: this.selectedAttraction[i].uid,
            event_uuid: this.selectedAttraction[i].bookingEventUid,
          });
        } else {
          selectedAttractionUid.push({
            attraction_uuid: this.selectedAttraction[i].uid,
          });
        }
      }
      let data = {
        citypass_uuid: uuid,
        quantity_adult: quantity_adult,
        quantity_kid: quantity_kid,
        started_at: date,
        attractions: selectedAttractionUid,
      };
      let response = await CartService.addToCart(data);
      if (response.status == "success") {
        this.isAdditionalAttraction = false;
        if (localStorage.count) {
          let count = JSON.parse(localStorage.count);
          localStorage.count = count + 1;
        } else {
          localStorage.count = 1;
        }
        setTimeout(this.showCart, 500);
        this.attractionListKey++;
        this.isChoseCitipass = false;
        this.choseAttractions = false;
        this.selectedAttraction = [];
        this.getAttractions();
      }
    },
    setSelectAttraction(attractions) {
      this.snackbar = false;
      this.selectedAttraction = attractions;
      localStorage.attractions = JSON.stringify(attractions);
      if (this.selectedAttraction.length < this.free_attraction_count) {
        this.choseAttractions = false;
      } else if (this.selectedAttraction.length == this.free_attraction_count) {
        // select not selected attractions
        let notSelectedAttr = this.attractionsList.filter(
          (attr_all) =>
            !this.selectedAttraction.find(
              (attr_selected) => attr_selected.uid === attr_all.uid
            )
        );

        if (notSelectedAttr.length) {
          // get info from citypass about not selected attraction
          let notSelectedAttrFromPass = this.citypassAttractions.filter(
            (attr_city) =>
              notSelectedAttr.find(
                (attr_not_selected) => attr_not_selected.uid === attr_city.uuid
              )
          );

          // get min max value for notification
          this.min_discount_attraction = Math.min(
            ...notSelectedAttrFromPass.map(
              (item) => item.discount_attraction_not_included
            )
          );
          this.max_discount_attraction = Math.max(
            ...notSelectedAttrFromPass.map(
              (item) => item.discount_attraction_not_included
            )
          );
        }

        this.choseAttractions = true;

        if (this.attractionsList.length != this.selectedAttraction.length) {
          (this.snackbarText = "attractions_selected_alert"),
            (this.choseAttractionSnackbarSuccess = true),
            setTimeout(() => {
              this.choseAttractionSnackbarSuccess = false;
            }, 6000);
        }
      }
    },
    showCart() {
      this.$emit("openCart");
    },
    setIsChooseCitypass(date, end_date, adultNum, childNum) {
      this.isChoseCitipass = true;
      this.startCitypassDate = date;
      this.endCitypassDate = end_date;
      this.adultNum = adultNum;
      this.childNum = childNum;
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
  },
  watch: {
    locale: {
      handler() {
        if (!this.isChoseCitipass) {
          this.attractionListKey++;
          this.getAttractions();

          let array = [];
          this.citypassList.forEach((el, index) =>
            el.header ? array.push(index) : null
          );
          this.citypassList[array[0]].header = this.$options.filters.localize(
            "city_passes_tourists"
          );
          this.citypassList[array[1]].header =
            this.$options.filters.localize("city_passes_locals");
        }
      },
    },
    isAdditionalAttraction: {
      deep: true,
      handler() {
        console.log("work watch", this.isAdditionalAttraction);
      },
    },
  },
};
</script>

<style></style>
