<template>
  <div style="min-height: 80vh">
    <!-- SNACKBARS -->
    <snackbar-success
      v-if="snackbar"
      :snackbar="snackbar"
      :snackbarText="snackbarText"
      :boldText="true"
    />
    <v-snackbar
      centered
      timeout="8000"
      v-model="choseAttractionSnackbarSuccess"
      color="success"
    >
      <v-row align="center">
        <v-icon class="mx-3">mdi-alert-circle-outline</v-icon>
        <v-col>
          <span class="font-weight-bold"
            >{{ "attractions_selected_alert" | localize }}
            {{ parseInt(selectedCitypass.discount_attraction * 100) }}%
            {{ "attractions_selected_alert_2" | localize }}
            {{ parseInt(selectedCitypass.discount_attraction_max * 100) }}%
            {{ "attractions_selected_alert_3" | localize }}</span
          >
        </v-col>
      </v-row></v-snackbar
    >
    <v-snackbar
      top
      v-model="dateSnackbar"
      :style="choseAttractionSnackbarSuccess ? 'top: 80px' : ''"
      color="deep-orange darken-1"
    >
      <v-row align="center">
        <v-icon class="mx-3">mdi-alert</v-icon>
        <v-col>
          <span
            >{{ "attractions_calendar_reminder_popup1" | localize }}
            {{ remainderDays + 1 }}
            {{ "attractions_calendar_reminder_popup2" | localize }}</span
          >
        </v-col>
      </v-row>
    </v-snackbar>
    <!-- END SNACKBARS -->

    <v-row align="center" style="position: relative; min-height: 80px">
      <h1>{{ "header_our_attractions_part1" | localize }}</h1>

      <v-slide-x-reverse-transition mode="out-in">
        <v-radio-group
          v-if="selectedCitypass.uuid"
          v-model="tab"
          row
          mandatory
          class="ml-5 mt-5"
        >
          <v-radio
            v-for="(category, index) in attractionCategories"
            :key="index"
            :label="
              $options.filters.localize('mzt_category_label') +
              ' ' +
              category.uuid
            "
            :value="index"
            :disabled="index != tab"
          ></v-radio>
        </v-radio-group>
      </v-slide-x-reverse-transition>
      <v-divider class="ml-3" />

      <v-btn
        v-if="admin"
        fab
        color="#fff"
        right
        dark
        small
        top
        absolute
        style="margin-top: 40px; margin-right: 50px"
        @click="orderingModal = true"
      >
        <v-icon color="#E62076">mdi-swap-vertical</v-icon>
      </v-btn>
      <v-btn
        v-if="admin"
        fab
        color="#E62076"
        right
        dark
        small
        top
        absolute
        style="margin-top: 40px"
        @click="openModal"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-row>

    <v-expand-transition>
      <v-row
        no-gutters
        justify="center"
        class="mb-3"
        v-if="selectedCitypass.uuid"
      >
        <v-col class="col-12 col-md-6 col-lg-4"></v-col>
        <v-col class="col-12 col-md-6 col-lg-8">
          <label
            class="ml-4"
            style="
              font-size: 18px;
              line-height: 18px;
              font-weight: 600;
              color: #000000;
            "
          >
            {{ "mobile_version_select_attractions_label1" | localize }}
            <span>{{ attractionCategories[tab].count }}</span>
            {{ "city_passes_label_attractions" | localize }}
            <span
              >{{ "select_attractions_label2" | localize }}
              {{ attractionCategories[tab].uuid }}</span
            >:
          </label></v-col
        >
      </v-row>
    </v-expand-transition>

    <v-row>
      <v-col class="col-12 col-md-6 col-lg-4">
        <attractions-buy-form
          ref="buy_form"
          @show_date_snackbar="showDateSnackbar"
          @set_citypass="setCitypass"
          :isAdditionalAttraction="isAdditionalAttraction"
          :disableBuyBtn="nextEnabled && tab + 1 == attractionCategories.length"
          :attractionsChoosed="selectedAttractionUid.length > getCount"
          @addToCart="addToCart"
          :loadingCart="loadingCart"
          @set_overlay="setOverlay()"
        />
        <v-card
          style="position: relative; z-index: 2"
          v-if="selectedCitypass.uuid && !nextEnabled"
          color="#e62076"
          class="px-5 mt-6"
          height="80px"
          id="choose_attr"
        >
          <v-btn
            v-if="overlay"
            @click="overlay = false"
            icon
            absolute
            small
            right
            color="gray"
            style="top: -10px; right: -10px; background-color: white"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <v-row align="center" class="white--text blink_me">
            <v-col>
              <span
                style="
                  font-family: 'Montserrat';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 19px;
                  line-height: 24px;
                "
              >
                {{ "mobile_version_select_attractions_label1" | localize }}
              </span>
              <span
                style="
                  font-family: 'Montserrat';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 19px;
                  line-height: 24px;
                "
              >
                {{ attractionCategories[tab].count }}
                {{ "city_passes_label_attractions" | localize }}
              </span>
              <span
                style="
                  font-family: 'Montserrat';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 19px;
                  line-height: 24px;
                "
              >
                {{ "select_attractions_label2" | localize }}
                {{ attractionCategories[tab].uuid }}
              </span>
            </v-col>
            <v-icon v-if="$vuetify.breakpoint.xs" x-large color="white">
              mdi-arrow-down
            </v-icon>
            <v-icon v-else x-large color="white">mdi-arrow-right</v-icon>
          </v-row>
        </v-card>
        <v-btn
          block
          class="mt-3"
          style="text-transform: none"
          @click="toNext"
          color="#e62076"
          dark
          v-if="nextEnabled && tab + 1 != attractionCategories.length"
          >{{ "cart_details_button_next" | localize }}</v-btn
        >
      </v-col>
      <v-col class="col-12 col-md-6 col-lg-8">
        <div
          v-if="loadingAttractions"
          class="d-flex align-center"
          style="min-height: 60vh"
        >
          <v-progress-circular
            v-if="loadingAttractions"
            class="mx-auto"
            indeterminate
            color="#E62076"
          ></v-progress-circular>
        </div>
        <all-attractions-list
          :attractionList="attractionsList"
          v-if="!selectedCitypass.uuid && !loadingAttractions"
        />
        <attractions-list
          ref="mazatlan_list"
          v-if="selectedCitypass && !loadingAttractions"
          :attractionCategories="attractionCategories"
          :selectedItems="selectedAttractionUid"
          :pcVersion="true"
          @setSelectedAttraction="setSelectedAttraction"
          @removeAttractionUid="removeAttractionUid"
          @next_enabled="nextEnable"
          :key="key"
        />
      </v-col>
    </v-row>
    <add-attraction-modal
      ref="modal"
      v-if="attractionModal"
      @close_modal="closeModal"
      @addedAtraction="
        attractionModal = false;
        getAllAttractions();
      "
    />
    <ordering-modal
      v-if="orderingModal"
      :visible="orderingModal"
      @success="
        orderingModal = false;
        getAllAttractions();
      "
      @close="orderingModal = false"
    />
    <v-overlay z-index="1" :value="overlay"></v-overlay>
  </div>
</template>

<script>
import AttractionsBuyForm from "./AttractionsBuyForm.vue";
import AttractionsService from "@/requests/Attractions/AttractionsService";
import AttractionsList from "@/components/mobile/attractions/attractionList/mazatlanAttractionList";
import CartService from "@/requests/Cart/CartService";
import userSettingsMixin from "@/mixins/userSettingsMixin";
import addAttractionModal from "../addAtractionModal.vue";
import allAttractionsList from "./AllAttractionsList.vue";
import AttractionService from "@/requests/Attractions/AttractionsService.js";
import orderingModal from "../orderingModal.vue";
import mztAttractionsMixin from "@/mixins/mztAttractionsMixin";
import _ from "lodash";

export default {
  components: {
    AttractionsBuyForm,
    AttractionsList,
    addAttractionModal,
    allAttractionsList,
    orderingModal,
  },
  mixins: [userSettingsMixin, mztAttractionsMixin],
  data: () => ({
    selectedCitypass: {},
    dateSnackbar: false,
    snackbar: false,
    snackbarText: "",
    choseAttractionSnackbarSuccess: false,
    remainderDays: 0,
    isAdditionalAttraction: false,
    attractionsList: [],
    allAttractionsList: [],
    attractionCategories: [],
    activeCategory: {},
    selectedAttractionUid: [],
    additionalAttractionUuid: [],
    tab: 0,
    tabsAttractions: [],
    nextEnabled: false,
    key: 0,
    loadingCart: false,
    loadingAttractions: false,
    attractionModal: false,
    orderingModal: false,
    overlay: false,
  }),
  mounted() {
    this.getAllAttractions();
  },
  destroyed() {
    if (this.selectedCitypass.uuid && this.selectedAttractionUid.length) {
      const data = {
        tab: this.tab,
        attractions: this.selectedAttractionUid,
      };
      this.setChoosedInfo(data);
    }
  },
  methods: {
    showDateSnackbar(count) {
      this.remainderDays = count;
      this.dateSnackbar = true;
    },
    nextEnable(value) {
      this.nextEnabled = value;
    },
    closeModal() {
      this.attractionModal = false;
    },
    openModal() {
      this.attractionModal = true;
    },
    toNext() {
      this.tab++;
      this.$refs.mazatlan_list.nextTab();
    },
    setOverlay() {
      document.getElementById("choose_attr").scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      this.overlay = true;
    },
    resetData() {
      this.key++;
      this.selectedAttractionUid = [];
      this.tab = 0;
      this.$refs.mazatlan_list.resetData();
      this.nextEnabled = false;
    },
    setCitypass(citypass) {
      console.log(citypass);
      this.selectedCitypass = citypass;

      this.resetData();

      if (citypass.uuid) {
        this.getAttractions();
      } else {
        this.attractionsList = [];
        this.attractionCategories = [];
        this.additionalAttractionUuid = [];
      }
    },
    async addToCart(
      uuid,
      date,
      quantity_adult,
      quantity_kid,
      //   free_attraction_count,
      includeGDLPublicTransport
    ) {
      this.loadingCart = true;
      if (includeGDLPublicTransport == true) {
        this.additionalAttractionUuid.forEach((attraction) => {
          this.setSelectedAttraction(attraction.uuid);
        });
      }

      let attractions = [];
      this.selectedAttractionUid.forEach((el) =>
        attractions.push({
          attraction_uuid: el,
        })
      );

      let data = {
        citypass_uuid: uuid,
        quantity_adult: quantity_adult,
        quantity_kid: quantity_kid,
        started_at: date,
        attractions: attractions,
      };
      let response = await CartService.addToCart(data);
      if (response.status == "success") {
        this.snackbarText = "attractions_city_pass_added_to_cart_notification";
        this.snackbar = true;
        this.snackbarSuccess = true;
        if (localStorage.count) {
          let count = JSON.parse(localStorage.count);
          localStorage.count = count + 1;
        } else {
          localStorage.count = 1;
        }

        this.$refs.buy_form.resetData();
        this.resetData();

        localStorage.removeItem("selectedCitypassId");
        localStorage.removeItem("date");
        localStorage.removeItem("adultNumber");
        localStorage.removeItem("childNumber");
        localStorage.removeItem("isIncludeGDLPublicTransport");

        this.setChoosedInfo({});

        this.getAllAttractions();

        setTimeout(() => {
          this.showCart();
          this.loadingCart = false;
        }, 700);
      } else {
        this.loadingCart = false;
      }
    },
    showCart() {
      this.$emit("openCart");
    },
    async getAllAttractions() {
      if (!localStorage.citypass_uuid && !localStorage.selectedCitypassId) {
        this.loadingAttractions = true;
        await AttractionService.getAttractions().then((res) => {
          this.filterAttractions(res.result);
        });
      }
    },
    async getAttractions() {
      this.loadingAttractions = true;
      let filteredAttractions = this.selectedCitypass.attractions.filter(
        (attraction) =>
          attraction.is_free == "0" && attraction.is_additional == "0"
      );
      this.additionalAttractionUuid = this.selectedCitypass.attractions.filter(
        (attraction) => attraction.is_additional == "1"
      );
      console.log(this.additionalAttractionUuid);
      if (this.additionalAttractionUuid.length == 0) {
        this.isAdditionalAttraction = false;
      } else {
        this.isAdditionalAttraction = true;
      }
      let strUid = "";
      filteredAttractions.forEach((attraction) => {
        strUid = strUid + `${attraction.uuid},`;
      });
      if (strUid !== "") {
        await AttractionsService.getAttractionsByUids(strUid).then((res) => {
          if (res.result !== null) {
            this.filterAttractions(res.result);
            this.showLoader = false;
          }
        });
      }
    },
    filterAttractions(attractions) {
      this.attractionsList = attractions;

      if (!this.admin) {
        this.attractionsList = this.attractionsList.filter(
          (attr) => attr.status == "Active"
        );
      }
      let filteredWorkingHours = [];

      for (let i = 0; i < this.attractionsList.length; i++) {
        if (typeof this.attractionsList[i].working_hours == "object") {
          filteredWorkingHours.push(this.attractionsList[i]);
        }
      }
      this.attractionsList = filteredWorkingHours;
      for (let index = 0; index < this.attractionsList.length; index++) {
        this.working_hoursValues = Object.values(
          this.attractionsList[index].working_hours
        );
        let working_hoursKey = Object.keys(
          this.attractionsList[index].working_hours
        );
        let working_hours = [];
        for (let i = 0; i < this.working_hoursValues.length; i++) {
          working_hours.push({
            day: working_hoursKey[i],
            from: this.working_hoursValues[i].from,
            to: this.working_hoursValues[i].to,
          });
        }
        var helper = {};
        var result = working_hours.reduce(function (groupHours, hours) {
          var key = hours.to + "-" + hours.from;
          if (!helper[key]) {
            helper[key] = Object.assign({}, hours); // create a copy of hours
            groupHours.push(helper[key]);
          } else {
            helper[key].day += "-" + hours.day;
          }

          return groupHours;
        }, []);
        this.attractionsList[index].working_hours = [...result];
      }

      if (this.selectedCitypass.uuid) {
        this.filterToCategories();
      } else {
        this.loadingAttractions = false;
      }
    },
    filterToCategories() {
      this.attractionCategories = [];
      this.selectedCitypass.attraction_categories.forEach((category) => {
        const attractions = this.attractionsList.filter((el) => {
          return el.category == category.uuid;
        });
        this.attractionCategories.push({
          count: parseInt(category.count),
          uuid: category.uuid,
          attractions: attractions,
        });
      });

      try {
        const data = { ...this.getChoosedInfo };
        console.log(data);
        if (!_.isEmpty(data)) {
          this.tab = data.tab;
          this.selectedAttractionUid = data.attractions;
        }
      } catch (e) {
        console.log(e);
      }
      this.loadingAttractions = false;
    },
    setSelectedAttraction(uid) {
      if (this.selectedAttractionUid.findIndex((el) => el === uid) == -1) {
        this.selectedAttractionUid.push(uid);

        console.log(this.selectedAttractionUid);

        //localStorage.attractions = JSON.stringify(this.selectedAttractionUid);

        if (
          this.selectedAttractionUid.length ==
          this.selectedCitypass.attraction_count
        ) {
          this.choseAttractionSnackbarSuccess = true;
        }
      }
    },
    removeAttractionUid(uid) {
      this.selectedAttractionUid = this.selectedAttractionUid.filter(
        (attractionUid) => attractionUid !== uid
      );

      console.log(this.selectedAttractionUid);
      //localStorage.attractions = JSON.stringify(this.selectedAttractionUid);
    },
  },
  computed: {
    getCount() {
      if (this.selectedCitypass.uuid) {
        let count = 0;
        for (let i = 0; i < this.tab; i++) {
          count += this.attractionCategories[i].count;
        }

        console.log(count);
        return count;
      } else {
        return 0;
      }
    },
  },
};
</script>