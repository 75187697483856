<template>
  <div>
    <v-row justify="center" class="mb-4 mt-1">
      <v-col
        cols="6"
        v-for="attraction in attractionList"
        :key="attraction.uid"
      >
        <attraction-card
          :attraction="attraction"
          :pcVersion="true"
          :disableSelect="true"
          :disableAgregarBtn="false"
          @setSelectedAttraction="setSelectedAttraction"
          @removeAttractionUid="removeAttractionUid"
        /> </v-col
    ></v-row>
  </div>
</template>

<script>
import attractionCard from "@/components/mobile/attractions/attractionList/attractionCard.vue";

export default {
  components: { attractionCard },
  data: () => ({
  }),
  props: {
    attractionList: {
      require: true,
    },
  },
  methods: {
    setSelectedAttraction(uid) {
      console.log(uid)
    },
    removeAttractionUid(uid) {
      console.log(uid)
    }
  },
};
</script>

<style>
</style>