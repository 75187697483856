<template>
  <div>
    <v-idle
      @idle="onidleSelectInfo"
      :events="['keydown', 'mousedown', 'touchstart']"
      hidden
      :duration="5"
      :loop="true"
    />
    <v-idle
      @idle="onidleSelectAttractions"
      :events="['keydown', 'mousedown', 'touchstart']"
      hidden
      :duration="15"
      :loop="true"
    />
    <v-snackbar
      centered
      v-model="selectSnackbar.visible"
      color="deep-orange darken-1"
      timeout="10000"
      :style="$vuetify.breakpoint.smAndDown ? 'top: -150px;' : 'top: -90px;'"
    >
      <v-row align="center">
        <v-icon class="mx-3">mdi-alert</v-icon>
        <v-col>
          {{ "attractions_selected_label1" | localize }}
            <br />
            {{ "attractions_selected_label" | localize }}
        </v-col>
      </v-row>
    </v-snackbar>
    <v-card class="rounded-lg pa-5">
      <v-row>
        <v-col>
          <span style="font-weight: 500; font-size: 20px" class="mb-3">
            {{ "attractions_choose_city_pass" | localize }}
          </span>

          <v-select
            style="cursor: pointer"
            outlined
            class="rounded-lg mt-2"
            background-color="white"
            dense
            :menu-props="{ bottom: true, offsetY: true }"
            :items="Object.values(citypassList)"
            :item-value="'uuid'"
            :item-text="'title_en'"
            return-object
            v-model="selectedCitypass"
            color="black"
            :placeholder="'attractions_choose_city_pass' | localize"
            hide-details
          >
            <template slot="item" slot-scope="{ item }">
              <div class="px-0">
                <v-row align="center" class="px-0">
                  <div
                    style="width: 5px; height: 30px"
                    :style="'background-color:' + item.color"
                  ></div>
                  &nbsp;
                  <span style="font-weight: 500; font-size: 14px">
                    {{ locale == "en" ? item.title_en : item.title_es }}
                  </span>
                </v-row>
              </div>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col class="black--text labelStyle col-6">
          {{ "attractions_starting_date" | localize }}
        </v-col>
        <v-col>
          <v-menu ref="menu">
            <template v-slot:activator="{ on, attrs }">
              <v-row justify="start" no-gutters align="center">
                <v-col cols="12" style="font-size: 12px">
                  <v-text-field
                    outlined
                    :value="
                      date
                        ? `${date.substr(5, 2)}/${date.substr(
                            8,
                            2
                          )}/${date.substr(0, 4)}`
                        : ''
                    "
                    :disabled="Object.entries(selectedCitypass).length == 0"
                    dense
                    class="rounded-lg"
                    background-color="white"
                    color="black"
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-calendar"
                    @click:append="on.click"
                    readonly
                    hide-details
                    :placeholder="'attractions_placeholder_starting' | localize"
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
            <v-date-picker
              v-model="date"
              color="#6ABAA4"
              :locale="locale"
              :allowed-dates="allowedDates"
              :min="
                new Date(
                  new Date().getTime() +
                    1 * 60 * 60 * 1000 -
                    new Date().getTimezoneOffset() * 60000
                )
                  .toISOString()
                  .substr(0, 10)
              "
              :max="
                new Date(
                  new Date().getTime() +
                    1464 * 60 * 60 * 1000 -
                    new Date().getTimezoneOffset() * 60000
                )
                  .toISOString()
                  .substr(0, 10)
              "
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row align="center" v-if="endDate">
        <v-col class="black--text labelStyle col-6">End Date</v-col>
        <v-col>
          <v-text-field
            outlined
            :value="
              endDate.toISOString().substr(5, 2) +
              '/' +
              endDate.toISOString().substr(8, 2) +
              '/' +
              endDate.toISOString().substr(0, 4)
            "
            dense
            class="rounded-lg end-date"
            background-color="white"
            color="black"
            append-icon="mdi-calendar"
            readonly
            hide-details
            :placeholder="'attractions_placeholder_starting' | localize"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="start" class="mt-3">
        <v-col class="labelStyle">
          {{ "attractions_adult" | localize }}
        </v-col>
        <v-col>
          <v-row align="center" justify="center">
            <v-btn
              icon
              color="black"
              x-large
              :disabled="!selectedCitypass.uuid || adultNumber == 0"
              @click="adultNumber > 0 ? adultNumber-- : (adultNumber = 0)"
            >
              <v-icon>mdi-minus-circle-outline</v-icon>
            </v-btn>
            <v-col cols="2" class="px-0 py-0" style="text-align: center">
              <span class="black--text labelStyle">{{ adultNumber }}</span>
            </v-col>
            <v-btn
              icon
              color="black"
              x-large
              @click="adultNumber < 15 ? adultNumber++ : (adultNumber = 15)"
              :disabled="!selectedCitypass.uuid || adultNumber == 15"
            >
              <v-icon>mdi-plus-circle-outline</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-row align="start">
        <v-col class="labelStyle">
          {{ "attractions_child" | localize }}
        </v-col>
        <v-col>
          <v-row align="center" justify="center">
            <v-btn
              icon
              color="black"
              x-large
              @click="childNumber > 0 ? childNumber-- : (childNumber = 0)"
              :disabled="!selectedCitypass.uuid || childNumber == 0"
            >
              <v-icon>mdi-minus-circle-outline</v-icon>
            </v-btn>
            <v-col class="px-0 py-0" cols="2" style="text-align: center">
              <span class="black--text labelStyle">{{ childNumber }}</span>
            </v-col>
            <v-btn
              icon
              color="black"
              x-large
              @click="childNumber < 40 ? childNumber++ : (childNumber = 40)"
              :disabled="!selectedCitypass.uuid || childNumber == 40"
            >
              <v-icon>mdi-plus-circle-outline</v-icon>
            </v-btn>
          </v-row></v-col
        >
      </v-row>
      <v-row>
        <v-col class="pt-0">
          <p>
            {{ "attractions_infants_label" | localize }}&nbsp;{{
              "attractions_infants_label_2" | localize
            }}
          </p>
        </v-col>
      </v-row>

      <!-- GDL Bus/Metro -->
      <v-row
        no-gutters
        justify="center"
        align="center"
        v-if="isAdditionalAttraction"
      >
        <v-divider color="black" style="border: 0.5px solid #000000" />
        <div
          style="
            width: 30px;
            height: 28px;
            object-fit: cover;
            margin-left: 21px;
            margin-right: 21px;
          "
        >
          <our-attraction-bus-icon color="black" />
        </div>
        <v-divider color="black" style="border: 0.5px solid #000000" />
      </v-row>
      <v-row
        no-gutters
        justify="center"
        align="center"
        class="px-0"
        style="margin-top: 10px; padding-bottom: 15px"
        v-if="isAdditionalAttraction"
      >
        <div style="width: 28px; height: 28px">
          <img
            src="@/assets/notChecked.svg"
            style="cursor: pointer"
            v-if="!isIncludeGDLPublicTransport"
            @click="isIncludeGDLPublicTransport = true"
          />
          <div style="" v-if="isIncludeGDLPublicTransport">
            <img
              src="@/assets/chekedTerm.svg"
              @click="isIncludeGDLPublicTransport = false"
              style="cursor: pointer"
            />
          </div>
        </div>

        <v-col cols="10" class="px-0 pl-4">
          <span
            style="
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;
              color: #000000;
            "
            >{{ "public_transport_label1" | localize
            }}<u style="cursor: pointer" @click="showTermsModal = true">{{
              "public_transport_label2" | localize
            }}</u
            >{{ "public_transport_label3" | localize }}</span
          >
        </v-col>
      </v-row>
      <!-- /GDL Bus/Metro -->
    </v-card>

    <v-btn
      block
      class="mt-3"
      style="text-transform: none"
      @click="addToCart(selectedCitypass.uuid)"
      color="#e62076"
      dark
      v-if="
        date && (adultNumber || childNumber) && disableBuyBtn && !loadingCart
      "
      >{{ "attractions_add_to_cart_button" | localize }}</v-btn
    >
  </div>
</template>

<script>
import CitypassService from "@/requests/Citypass/CitypassService";
import userSettingsMixin from "@/mixins/userSettingsMixin";
import CartService from "@/requests/Cart/CartService";

export default {
  mixins: [userSettingsMixin],
  data: () => ({
    citypassList: [],
    selectedCitypass: {},
    date: "",
    endDate: null,
    allowedDates: "",
    childNumber: 0,
    adultNumber: 0,
    showTermsModal: false,
    isIncludeGDLPublicTransport: false,
    noGdlModal: false,
    typeConfirm: "",
    disabledCart: false,
    selectSnackbar: {
      visible: false,
      used: false,
    },
    overlay: false,
  }),
  props: {
    isAdditionalAttraction: {
      require: true,
    },
    disableBuyBtn: {
      require: true,
    },
    loadingCart: {
      require: true,
    },
    attractionsChoosed: {
      require: true,
    },
  },
  destroyed() {
    localStorage.removeItem("citypass_uuid");
  },
  mounted() {
    this.getCitypasses();
  },
  methods: {
    onidleSelectInfo() {
      if (
        this.selectedCitypass.uuid &&
        this.disableBuyBtn &&
        (!this.date ||
          parseInt(this.adultNumber) + parseInt(this.childNumber) == 0) &&
        !this.selectSnackbar.used
      ) {
        this.selectSnackbar.visible = true;
        this.selectSnackbar.used = true;
      }
    },
    onidleSelectAttractions() {
      if (
        this.selectedCitypass.uuid &&
        (this.adultNumber || this.childNumber) &&
        this.date &&
        !this.attractionsChoosed &&
        !this.overlay
      ) {
        this.$emit("set_overlay");
      }
    },
    resetData() {
      this.selectedCitypass = {};
      this.date = "";
      this.endDate = null;
      this.childNumber = 0;
      this.adultNumber = 0;
      this.isIncludeGDLPublicTransport = false;
    },
    async getCitypasses() {
      let response = await CitypassService.getCitypasses();
      let citypassList = response.result;

      let localList = [...citypassList].filter(function (el) {
        return el.type == "1" && el.status == "1";
      });
      let touristsList = [...citypassList].filter(function (el) {
        return el.type == "0" && el.status == "1";
      });

      let cityPassFiltered = [];

      cityPassFiltered.push({
        header: this.$options.filters.localize("city_passes_tourists"),
      });
      cityPassFiltered = cityPassFiltered.concat(touristsList);

      cityPassFiltered.push({
        header: this.$options.filters.localize("city_passes_locals"),
      });
      cityPassFiltered = cityPassFiltered.concat(localList);

      this.citypassList = cityPassFiltered;

      if (localStorage.citypass_uuid) {
        this.date = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);

        let citypass = this.citypassList.filter((el) => {
          return el.uuid == localStorage.citypass_uuid;
        })[0];
        this.selectedCitypass = citypass;
      } else if (localStorage.selectedCitypassId) {
        let citypass = this.citypassList.filter((el) => {
          return el.uuid == localStorage.selectedCitypassId;
        })[0];

        this.selectedCitypass = citypass;
      }
    },
    choosedConfirm(check) {
      this.noGdlModal = false;
      if (this.typeConfirm == "cart") {
        if (check) {
          if (
            !this.isIncludeGDLPublicTransport &&
            this.isAdditionalAttraction
          ) {
            this.typeConfirm = "noBus";
            setTimeout(() => {
              this.noGdlModal = true;
            }, 200);
          } else {
            this.$emit(
              "addToCart",
              this.selectedCitypass.uuid,
              this.date,
              this.adultNumber,
              this.childNumber,
              this.isIncludeGDLPublicTransport
            );
            this.disabledCart = false;
          }
        } else {
          this.disabledCart = false;
        }
      } else {
        this.isIncludeGDLPublicTransport = check;
        this.$emit(
          "addToCart",
          this.selectedCitypass.uuid,
          this.date,
          this.adultNumber,
          this.childNumber,
          this.isIncludeGDLPublicTransport
        );
        this.disabledCart = false;
      }
    },
    addToCart() {
      console.log(this.date);
      if (this.adultNumber !== 0) {
        this.checkCitypassInCart();
      }
    },
    async checkCitypassInCart() {
      this.disabledCart = true;
      this.typeConfirm = "";
      let cart = await CartService.getCart();
      let response = cart.result.list;

      let check = false;
      response.forEach((el) => {
        if (
          el.citypass_uuid == this.selectedCitypass.uuid &&
          el.started_at.substr(0, 10) == this.date
        ) {
          this.noGdlModal = true;
          this.typeConfirm = "cart";
          check = true;
          return;
        }
      });

      if (!check) {
        if (!this.isIncludeGDLPublicTransport && this.isAdditionalAttraction) {
          this.noGdlModal = true;
          this.typeConfirm = "noBus";
        } else {
          this.$emit(
            "addToCart",
            this.selectedCitypass.uuid,
            this.date,
            this.adultNumber,
            this.childNumber,
            this.selectedCitypass.attraction_count,
            this.isIncludeGDLPublicTransport
          );
          this.disabledCart = false;
          // this.selectedCitypassId = "";
          // this.selectedCitypass = {};
        }
      }
    },
  },
  watch: {
    disableBuyBtn: {
      handler() {
        this.selectSnackbar.used = false
      }
    },
    locale: {
      handler() {
        let array = [];
        this.citypassList.forEach((el, index) =>
          el.header ? array.push(index) : null
        );
        this.citypassList[array[0]].header = this.$options.filters.localize(
          "city_passes_tourists"
        );
        this.citypassList[array[1]].header =
          this.$options.filters.localize("city_passes_locals");
      },
    },
    selectedCitypass: {
      deep: true,
      handler() {
        if (localStorage.selectedCitypassId == this.selectedCitypass.uuid) {
          if (localStorage.date) {
            this.date = localStorage.date;
          }

          if (localStorage.adultNumber) {
            this.adultNumber = localStorage.adultNumber;
          }

          if (localStorage.childNumber) {
            this.childNumber = localStorage.childNumber;
          }

          if (localStorage.isIncludeGDLPublicTransport) {
            this.isIncludeGDLPublicTransport =
              localStorage.isIncludeGDLPublicTransport;
          }
        } else {
          this.date = "";
          this.endDate = null;
          this.childNumber = 0;
          this.adultNumber = 0;
          this.selectSnackbar.used = false;
          this.isIncludeGDLPublicTransport = false;
        }
        if (this.selectedCitypass.uuid) {
          localStorage.selectedCitypassId = this.selectedCitypass.uuid;
        }

        this.$emit("set_citypass", this.selectedCitypass);
        if (
          this.selectedCitypass.days &&
          this.selectedCitypass.days != "1,2,3,4,5,6,7"
        ) {
          this.allowedDates = (val) =>
            (new Date(val).getDay() >=
              this.selectedCitypass.days.substr(0, 1) &&
              new Date(val).getDay() <=
                this.selectedCitypass.days.substr(-1)) ||
            (new Date(val).getDay() == 0 &&
              this.selectedCitypass.days.substr(-1) == "7");
        } else {
          this.allowedDates = "";
        }
      },
    },
    date: {
      deep: true,
      handler() {
        if (this.date) {
          localStorage.date = this.date;
        }
        if (this.selectedCitypass && this.date) {
          var endDate = new Date(this.date);
          if (
            this.selectedCitypass.days &&
            this.selectedCitypass.days != "1,2,3,4,5,6,7"
          ) {
            let remainderDays = 0;
            remainderDays =
              this.selectedCitypass.days.substr(-1) -
              new Date(this.date).getDay();
            endDate.setDate(endDate.getDate() + parseInt(remainderDays));

            if (remainderDays == 7) {
              remainderDays = 0;
            }

            console.log(remainderDays, this.selectedCitypass.term);
            if (
              parseInt(remainderDays) + 1 <
              parseInt(this.selectedCitypass.term)
            ) {
              this.$emit("show_date_snackbar", remainderDays);
            }
          } else {
            endDate.setDate(
              endDate.getDate() + parseInt(this.selectedCitypass.term) - 1
            );
          }
          this.endDate = endDate;
        }
      },
    },
    isIncludeGDLPublicTransport: {
      handler() {
        if (this.isIncludeGDLPublicTransport) {
          localStorage.isIncludeGDLPublicTransport =
            this.isIncludeGDLPublicTransport;
        } else {
          localStorage.removeItem("isIncludeGDLPublicTransport");
        }
      },
    },
    adultNumber: {
      deep: true,
      handler() {
        if (this.adultNumber) {
          localStorage.adultNumber = this.adultNumber;
        }
      },
    },
    childNumber: {
      deep: true,
      handler() {
        if (this.childNumber) {
          localStorage.childNumber = this.childNumber;
        }
      },
    },
  },
};
</script>

<style scoped>
.labelStyle {
  font-size: 18px;
}
.end-date {
  color: black !important;
  pointer-events: none;
  cursor: pointer;
  opacity: 0.6;
}
</style>