<template>
    <v-dialog v-model="visibility" max-width="600px" persistent>
    <v-card>
      <v-card-title style="word-break: break-word !important">
        {{ "attraction_people_amount_booking_validation" | localize }}
      </v-card-title>
      <v-card-actions class="pb-3">
        <v-row>
          <v-col>
            <v-btn block text color="#E62076" @click="onChoose(false)">
              {{ "button_no" | localize }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn color="#E62076" dark block @click="onChoose(true)">
              {{ "button_yes" | localize }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import modalMixin from "../../mixins/modalMixin";
export default {
  mixins: [modalMixin],
  methods: {
    onChoose(check) {
      this.$emit("choosed", check);
    },
  },
};
</script>