<template>
  <div>
    <div
      v-if="showLoader && attractions.length == 0"
      class="d-flex align-center"
      style="min-height: 80vh"
    >
      <v-progress-circular
        v-if="showLoader"
        class="mx-auto"
        indeterminate
        color="#E62076"
      ></v-progress-circular>
    </div>
    <div
      v-if="attractions.length === 0 && !showLoader"
      class="d-flex align-center justify-center"
      style="min-height: 80vh"
    >
      <span>There are no attractions yet</span>
    </div>
    <div
      v-for="(attraction, index) in attractions"
      :key="attraction.uid"
      class="mb-10"
      v-else
    >
      <v-row
        :key="key"
        :class="attraction.status == 'Inactive' ? 'disabled' : ''"
        justify="center"
        :style="
          $vuetify.breakpoint.xs
            ? 'display: block !important; text-align:start;'
            : ''
        "
      >
        <v-row
          no-gutters
          :justify="$vuetify.breakpoint.xs ? 'center' : 'start'"
        >
          <v-col
            cols="8"
            lg="12"
            md="12"
            xl="12"
            sm="8"
            :class="$vuetify.breakpoint.xs ? 'ml-6' : 'mt-3'"
          >
            <div
              class="checkbox"
              style="cursor: pointer"
              v-if="
                index !== checkedNum &&
                isChoseCitipass &&
                attraction.status == 'Active'
              "
              @click="
                attraction.is_booking_require == 'Yes'
                  ? bookingAttraction(attraction)
                  : checkAttraction(index, attraction)
              "
            />
            <!-- @click="
                checkAttraction(index, attraction)
              " -->

            <img
              src="@/assets/checked.svg"
              alt="checked"
              class="checked"
              @click="deleteCheckedAttraction(attraction.uid, index)"
              v-if="checkedNum.includes(index) && isChoseCitipass"
            />
            <div v-if="attraction.images.length > 0">
              <div v-for="(image, i) in attraction.images" :key="i">
                <router-link :to="'/attractions/' + attraction.uid">
                  <v-img
                    style="
                      border-radius: 10%;
                      object-fit: cover;
                      height: 221px !important;
                    "
                    width="221px"
                    v-if="i == 0"
                    :src="image.thumbs[2]"
                    alt="no img"
                /></router-link>
              </div>
            </div>
            <div v-else>
              <router-link :to="'/attractions/' + attraction.uid">
                <v-img
                  style="
                    border-radius: 10%;
                    object-fit: cover;
                    height: 221px !important;
                  "
                  width="221px"
                  src="@/assets/IoP.png"
                  alt="no img"
              /></router-link>
            </div>
          </v-col>
        </v-row>
        <v-col
          cols="12"
          lg="8"
          md="6"
          xl="8"
          sm="8"
          :class="$vuetify.breakpoint.xs ? 'px-5' : ''"
        >
          <h2>
            <router-link
              :to="'/attractions/' + attraction.uid"
              style="text-decoration: none; color: #e62076"
              >{{ attraction.title }}</router-link
            >
          </h2>
          <v-row align="center" class="mt-1">
            <img
              src="@/assets/Vector.svg"
              class="ml-3"
              style="align-self: start; margin-top: 3px"
            />
            <v-col
              cols="10"
              lg="11"
              md="10"
              xl="11"
              sm="11"
              class="px-0 py-0 ml-2"
            >
              <span class="font-weight-bold"
                ><a
                  :href="`https://www.google.com/maps/search/?api=1&query=${attraction.geo_lat},${attraction.geo_lon}`"
                  target="_blank"
                  style="text-decoration: none; color: black"
                  ><u>{{ attraction.address }}</u></a
                >
              </span>
            </v-col>
          </v-row>
          <br />
          <v-row
            class="pt-1"
            v-if="attraction.closest_stop && attraction.closest_stop !== ''"
          >
            <img src="@/assets/Buses.svg" class="ml-3" />
            <v-col
              cols="10"
              lg="11"
              md="10"
              xl="11"
              sm="11"
              class="px-0 py-0 ml-2"
            >
              <span class="font-weight-bold">
                {{ attraction.closest_stop }}
              </span>
            </v-col>
          </v-row>
          <br
            v-if="attraction.closest_stop && attraction.closest_stop !== ''"
          />
          <v-row
            :align="$vuetify.breakpoint.xs ? 'start' : 'center'"
            class="pt-1"
          >
            <img
              src="@/assets/Time.svg"
              class="ml-3"
              :class="$vuetify.breakpoint.xs ? 'pt-1' : ''"
            />
            <v-col
              cols="10"
              lg="11"
              md="10"
              xl="11"
              sm="11"
              class="px-0 py-0 ml-2"
            >
              <div v-if="!$vuetify.breakpoint.xs">
                <!-- Hours list for other screen -->

                <span
                  class="font-weight-bold"
                  v-for="(key, index) in attraction.working_hours"
                  :key="index"
                >
                  <span v-if="key.day.length > 3">
                    {{ key.day.substr(0, 3) | localize }}-{{
                      key.day.substr(key.day.length - 3, key.day.length)
                        | localize
                    }}:&nbsp;{{ key.from + "-" + key.to
                    }}<span v-if="index != attraction.working_hours.length - 1"
                      >,
                    </span>
                  </span>
                  <span v-else>
                    {{ key.day.substr(0, 3) | localize }}:&nbsp;{{
                      key.from + "-" + key.to
                    }}<span v-if="index != attraction.working_hours.length - 1"
                      >,
                    </span>
                  </span>
                </span>
              </div>
              <!-- /Hours list for other screen -->
              <!-- Hours list for mobile screen  -->
              <v-row no-gutters align="start" class="pt-1" v-else>
                <span class="font-weight-bold"> </span>
                <v-col cols="7">
                  <span
                    class="font-weight-bold"
                    v-for="(key, index) in attraction.working_hours"
                    :key="index"
                  >
                    <span v-if="key.day.length > 3">
                      {{ key.day.substr(0, 3) | localize }}-{{
                        key.day.substr(key.day.length - 3, key.day.length)
                          | localize
                      }}:&nbsp;{{ key.from + "-" + key.to
                      }}<span
                        v-if="index != attraction.working_hours.length - 1"
                        >,
                      </span>
                    </span>
                    <span v-else>
                      {{ key.day.substr(0, 3) | localize }}:&nbsp;{{
                        key.from + "-" + key.to
                      }}<span
                        v-if="index != attraction.working_hours.length - 1"
                        >,
                      </span>
                    </span>
                  </span>
                </v-col>
              </v-row>

              <!-- /Hours list for mobile screen  -->
            </v-col>
          </v-row>
          <v-row
            align="start"
            v-if="
              (attraction.price_kid && attraction.price_adults) ||
              attraction.price_kid == '0' ||
              attraction.price_adults == '0'
            "
            class="pt-1 ml-0"
          >
            <img src="@/assets/price-icon.svg" style="padding-top: 3px" />
            <v-col
              cols="10"
              lg="11"
              md="10"
              xl="11"
              sm="11"
              class="px-0 py-0 ml-2"
            >
              <span class="font-weight-bold" style="color: #00862c">
                {{ "attractions_mx_label" | localize }}
                {{ Math.ceil(attraction.price_adults) }}
                {{ "city_passes_label_adults" | localize }},
                {{ Math.ceil(attraction.price_kid) }}
                {{ "attractions_prices_label2" | localize }}
              </span>
            </v-col>
          </v-row>
          <v-row align="start" v-if="attraction.conditions" class="pt-1 ml-0">
            <img src="@/assets/prebookingIcon.svg" style="padding-top: 4px" />
            <v-col
              cols="10"
              lg="11"
              md="10"
              xl="11"
              sm="11"
              class="px-0 py-0 ml-2"
            >
              <span class="font-weight-bold" style="color: #f44336">
                <div v-html="attraction.conditions" />
              </span>
            </v-col>
          </v-row>
          <div class="black--text mt-4">{{ attraction.short_description }}</div>
          <!-- NEW FIGMA DESIGN FOR PREBOOKING -->
          <!-- <div
            class="booking_text"
            v-if="attraction.booking_requirements"
            @click="showModal(attraction.booking_requirements)"
          >
            <div
              class="mt-3"
              style="
                font-size: 15px;
                font-weight: 500;
                cursor: pointer;
                color: #3e81de;
                text-decoration: underline;
              "
            >
              {{ "booking_required_label" | localize }}
            </div>
          </div> -->
          <v-row no-gutters align="center" v-if="isChoseCitipass">
            <v-btn
              width="148px"
              height="32px"
              class="bookHereBtn"
              @click="
                checkedNum.includes(index)
                  ? deleteCheckedAttraction(attraction.uid, index)
                  : attraction.is_booking_require == 'Yes'
                  ? bookingAttraction(attraction)
                  : checkAttraction(index, attraction)
              "
              :disabled="
                checkedAttraction.length >= citypassFreeActtraction &&
                bookingAttractions.findIndex(
                  (time) => time.attraction_uuid == attraction.uid
                ) == -1 &&
                !checkedNum.includes(index)
              "
              :style="
                checkedNum.includes(index)
                  ? 'opacity: .6'
                  : checkedAttraction.length >= citypassFreeActtraction
                  ? 'border-bottom: 0px;'
                  : ''
              "
              >{{
                checkedNum.includes(index)
                  ? "remove_button"
                  : "attractions_prebooking_book_button" | localize
              }}</v-btn
            >
            <!--  @click="
                checkedNum.includes(index)
                  ? deleteCheckedAttraction(attraction.uid, index)
                  : attraction.is_booking_require == 'Yes'
                  ? checkAttraction(index, attraction)
                  : checkAttraction(index, attraction)
              " -->
            <button
              class="choosedDate"
              v-if="bookingTime(attraction.uid)"
              @click="deleteCheckedAttraction(attraction.uid, index)"
            >
              {{
                new Date(
                  adaptDate(bookingTime(attraction.uid).started_at)
                ).toLocaleTimeString("en-GB", {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              }}
              -
              {{
                new Date(
                  adaptDate(bookingTime(attraction.uid).ended_at)
                ).toLocaleTimeString("en-GB", {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              }}
              {{
                new Date(
                  bookingTime(attraction.uid).ended_at
                ).toLocaleDateString(
                  loggedUser
                    ? loggedUser.locale == "en"
                      ? "en-GB"
                      : "es-ES"
                    : user.locale == "en"
                    ? "en-GB"
                    : "es-ES",
                  {
                    weekday: "long",
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  }
                )
              }}
              <v-icon size="20px" color="rgba(0, 0, 0, 0.87)" class="ml-1"
                >mdi-close</v-icon
              >
            </button>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <booking-modal
      v-if="isShowModal && showAgree"
      @close_modal="
        isShowModal = false;
        text = null;
        showAgree = false;
      "
      @onConfirm="
        isShowModal = false;
        text = null;
        showAgree = false;
        onConfirmCheck();
      "
      :text="text"
    />
    <terms-modal
      v-if="isShowModal && !showAgree"
      @close_modal="
        isShowModal = false;
        text = null;
      "
      :text="text"
      rules="booking"
    />
    <booking-attraction-modal
      v-if="showBookingAttractionModal"
      :visible="showBookingAttractionModal"
      :attraction="attractionForBooking"
      :startCitypassDate="startCitypassDate"
      :endCitypassDate="endCitypassDate"
      :adultNum="adultNum"
      :childNum="childNum"
      @chooseTime="chooseTimeForBookingAttraction"
      @close="showBookingAttractionModal = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dateMixin from '../../mixins/dateMixin';
import BookingModal from "../UI/bookingModal.vue";
import termsModal from "../UI/TermsModal.vue";
import BookingAttractionModal from "./bookingAttractionModal.vue";

export default {
  mixins: [dateMixin],
  components: {
    BookingModal,
    termsModal,
    BookingAttractionModal,
  },
  data: () => ({
    checked: false,
    checkedNum: [],
    checkedAttraction: [],
    attractions: [],
    bookingAttractions: [],
    showLoader: true,
    admin: false,
    isShowModal: false,
    text: null,
    disableDraggable: true,
    showAgree: false,
    showBookingAttractionModal: false,
    checkAttractionInfo: {},
    attractionForBooking: {},
    key: 0,
  }),
  props: {
    attractionsList: {
      require: true,
    },
    isChoseCitipass: {
      require: true,
    },
    citypassFreeActtraction: {
      require: true,
    },
    startCitypassDate: {
      require: true,
    },
    endCitypassDate: {
      require: true,
    },
    adultNum: {
      require: true,
    },
    childNum: {
      require: true,
    },
  },
  methods: {
    onConfirmCheck() {
      this.checkedAttraction.length < this.citypassFreeActtraction
        ? ((this.checked = !this.checked),
          this.checkedNum.push(this.checkAttractionInfo.index),
          this.checkedAttraction.push(this.checkAttractionInfo.attraction),
          (this.checkAttractionInfo = {}))
        : "";
    },
    checkAttraction(index, attraction, showAgree, bookingEventUid) {
      if (
        attraction.is_booking_require == "Yes" &&
        this.checkedAttraction.length < this.citypassFreeActtraction &&
        !showAgree
      ) {
        this.checkAttractionInfo = {
          index: index,
          attraction: attraction,
        };
        this.showAgree = true;
        this.showModal(attraction.booking_requirements);
      } else {
        this.checkedAttraction.length < this.citypassFreeActtraction &&
        this.checkedAttraction.findIndex(
          (checked_attraction) => checked_attraction.uid == attraction.uid
        ) == -1
          ? ((this.checked = !this.checked),
            this.checkedNum.push(index),
            this.checkedAttraction.push({
              ...attraction,
              bookingEventUid: bookingEventUid,
            }))
          : "";
      }
      console.log(this.checkedAttraction);
    },
    showModal(text) {
      this.isShowModal = true;
      this.text = text;
    },
    deleteFromBuyForm(events) {
      events.forEach((el) => {
        let index = this.attractionsList.findIndex(
          (attraction) => attraction.uid == el.attraction_uuid
        );
        this.deleteCheckedAttraction(el.attraction_uuid, index);
      });
    },
    deleteCheckedAttraction(uid, index) {
      this.checkedAttraction = this.checkedAttraction.filter(
        (attract) => !attract.uid.includes(uid)
      );
      this.bookingAttractions = this.bookingAttractions.filter(
        (attraction) => attraction.attraction_uuid !== uid
      );
      localStorage.booking_attractions = JSON.stringify(
        this.bookingAttractions
      );
      this.checkedNum = this.checkedNum.filter((num) => num !== index);
    },
    chooseTimeForBookingAttraction(time) {
      let index = this.attractionsList.findIndex(
        (attraction) => attraction.uid == this.attractionForBooking.uid
      );
      this.checkAttraction(index, this.attractionForBooking, true, time.uuid);
      let attractionIndex = this.bookingAttractions.findIndex(
        (attraction) =>
          attraction.attraction_uuid == this.attractionForBooking.uid
      );
      if (attractionIndex == -1) {
        this.bookingAttractions.push(time);
      } else {
        this.bookingAttractions[attractionIndex] = time;
      }
      localStorage.booking_attractions = JSON.stringify(
        this.bookingAttractions
      );
      this.showBookingAttractionModal = false;
      this.attractionForBooking = {};
      this.key++;
    },
    bookingAttraction(attraction) {
      let isBookedAttraction = this.bookingTime(attraction.uid);
      if (isBookedAttraction) {
        this.attractionForBooking = {
          ...attraction,
          eventUid: isBookedAttraction.uuid,
        };
      } else if (this.checkedAttraction.length < this.citypassFreeActtraction) {
        this.attractionForBooking = attraction;
      }
      if (
        this.checkedAttraction.length < this.citypassFreeActtraction ||
        this.bookingAttractions.findIndex(
          (time) => time.attraction_uuid == attraction.uid
        ) !== -1
      ) {
        setTimeout(() => {
          this.showBookingAttractionModal = true;
        }, 200);
      }
    },
    bookingTime(uuid) {
      let time = this.bookingAttractions.filter(
        (attraction) => attraction.attraction_uuid == uuid
      );
      return time?.[0] || "";
    },
  },
  mounted() {
    if (this.loggedUser && this.loggedUser.admin) {
      this.admin = true;
      this.disableDraggable = false;
    }
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
  },
  watch: {
    loggedUser: {
      handler() {
        if (this.loggedUser && this.loggedUser.admin) {
          this.admin = true;
          this.disableDraggable = false;
        }
      },
    },
    attractionsList: {
      deep: true,
      handler() {
        this.checkedNum = [];
        this.attractions = this.attractionsList;
        this.showLoader = false;
        if (localStorage.attractions && this.attractions.length > 0) {
          this.checkedAttraction = JSON.parse(localStorage.attractions);
          this.checkedNum = JSON.parse(localStorage.checkedNum);
        }
        if (localStorage.booking_attractions) {
          this.bookingAttractions = JSON.parse(
            localStorage.booking_attractions
          );
        }
      },
    },
    bookingAttractions: {
      deep: true,
      handler() {
        this.$emit("setBookingEvents", this.bookingAttractions);
      },
    },
    "attractionsList.length": {
      deep: true,
      handler() {
        console.log("work");
      },
    },
    checkedAttraction: {
      deep: true,
      handler() {
        localStorage.checkedNum = JSON.stringify(this.checkedNum);
        this.$emit("selectAttraction", this.checkedAttraction);
      },
    },
    isChoseCitipass: {
      deep: true,
      handler() {
        if (this.isChoseCitipass) {
          this.disableDraggable = true;
        } else {
          this.disableDraggable = false;
        }
        //this.checkedAttraction = [];
      },
    },
    showBookingAttractionModal: {
      handler() {
        localStorage.setItem(
          "showBookingAttractionModal",
          this.showBookingAttractionModal
        );
      },
    },
  },
};
</script>

<style>
.checkbox {
  position: absolute;
  z-index: 5;
  margin-left: 10px;
  background: #ffffff;
  border: 2px solid #40b4e5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 28px;
  height: 28px;
  margin-top: 10px;
}
.checked {
  z-index: 5;
  margin-left: 6px;
  margin-top: 10px;
  cursor: pointer;
  position: absolute;
}
.disabled {
  opacity: 0.2;
}
.booking_text {
  cursor: pointer;
}
.booking_text:hover {
  text-decoration: underline;
}
.bookHereBtn {
  border-radius: 6px;
  border-bottom: 2px solid #2a9d8e;
  background: #6abaa4 !important;
  color: #fff !important;
  text-align: center;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 700;
  text-transform: none;
  margin-top: 12px;
}
.choosedDate {
  display: flex;
  height: 32px;
  padding: 0px 12px 0px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.12);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: -0.266px;
  color: #212121;
  margin-top: 14px;
  margin-left: 20px;
}
</style>